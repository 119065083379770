import Axios, { InternalAxiosRequestConfig } from "axios";
import Logger from "../common/Logger";
import AppSettingsService from "./AppSettingsService";

export default class ApiSettings {
  private static instance: ApiSettings | undefined;

  private constructor() {}

  public static getBaseUrl() {
    return AppSettingsService.getBaseUrl();
  }

  public static initialize() {
    return ApiSettings.getInstance();
  }

  public static reset() {
    ApiSettings.instance = undefined;
    Axios.interceptors.request.clear();
  }

  public static getInstance() {
    if (!ApiSettings.instance) {
      ApiSettings.instance = new ApiSettings();
      ApiSettings.instance.configure();
    }
    return ApiSettings.instance;
  }

  /**
   * The access token interceptor attempts to attach a bearer token to all logged in requests coming from the client to the backend.
   */
  private static async accessTokenInterceptor(
    request: InternalAxiosRequestConfig,
  ) {
    Logger.logFine("Attempting to attach access token...");
    const token = sessionStorage.getItem("access_token") || undefined;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
      Logger.logFine(`Bearer token set. Sending authenticated request.`);
    } else {
      Logger.logFine("Sending unauthenticated request.");
    }
    return request;
  }

  /** The request logging interceptor very minimally logs all requests made client side. */
  private static requestLoggingInterceptor(
    request: InternalAxiosRequestConfig,
  ) {
    Logger.logInfo(
      `[${request.method!.toUpperCase()}] ${request.baseURL}${request.url}`,
    );
    return request;
  }

  private configure() {
    Axios.defaults.baseURL = ApiSettings.getBaseUrl();
    Logger.logInfo(`Processing requests from: ${ApiSettings.getBaseUrl()}`);

    Logger.logInfo("Enforcing request authorization.");
    Axios.interceptors.request.use(ApiSettings.accessTokenInterceptor);

    // Add request logging interceptor.
    Logger.logInfo("Logging all axios requests.");
    Axios.interceptors.request.use(ApiSettings.requestLoggingInterceptor);
  }
}
