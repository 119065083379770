import Axios, { AxiosProgressEvent } from "axios";
import Logger from "../common/Logger";
import UserInfo from "./interface/oauth/UserInfo";

export default class FileApi {
  public static async uploadPageContent(
    user: UserInfo | undefined,
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<string> {
    if (!file || !user?.contentSas) {
      return "";
    }
    const randomFolderName = FileApi.generateRandomFolderName();

    const urlBuilder = FileApi.getBaseUrl();
    const blobName = encodeURIComponent(file.name);
    urlBuilder.pathname = `/files/drafts/${randomFolderName}/${blobName}`;
    urlBuilder.search = user.contentSas;
    const fileUrl = urlBuilder.toString();

    try {
      const fileData = await file.arrayBuffer();
      const axiosInstance = Axios.create();
      delete axiosInstance.defaults.headers.common["Authorization"];

      await axiosInstance.put(fileUrl, new Uint8Array(fileData), {
        headers: {
          "x-ms-version": "2023-01-03",
          "x-ms-blob-type": "BlockBlob",
          "x-ms-blob-content-type": file.type,
          "Content-Type": "application/octet-stream",
        },
        withCredentials: false,
        onUploadProgress: onUploadProgress,
      });

      return fileUrl; // Return the URL to the uploaded file
    } catch (exception: any) {
      Logger.logError(exception);
    }

    return "";
  }

  private static getBaseUrl() {
    if (window.location.hostname === "localhost") {
      return new URL("https://sportal-adt.ct-costco.com");
    }
    return new URL(window.location.origin);
  }

  public static generateRandomFolderName() {
    return (
      Math.random().toString(36).substring(2, 7) +
      Math.random().toString(36).substring(2, 7)
    );
  }
}
