import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ColDef, ColGroupDef, RowClickedEvent } from "ag-grid-community";
import { useCallback, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import ItemData from "../../../api/interface/cdh/ItemData";
import {
  BBAVendorItemTableColumns,
  CategoriesColDefs,
  CertificationsColDefs,
  CommoditiesColDefs,
  DepartmentColDef,
  DescriptionColDefsForCostcoUsers,
  DescriptionColDefsForVendorUsers,
  OrganicColDef,
  PalmOilColDef,
  VendorBBAColDef,
  VendorNameColDef,
} from "./VendorDetailsPageColumnDefinitions";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import ItemServerSideDatasource from "../../../api/GridDataSources/ItemServerSideDataSource";
import DefaultGrid from "../../../components/tables/DefaultGrid";
import SearchBar from "../../../components/common/SearchBar";
import { generateQueryParams } from "../../../util/Helper";
import useAuth from "../../../hooks/useAuth";
import PermissionService from "../../../permissions/PermissionService";
import ColumnCustomizeFilter from "../../../components/common/ColumnCustomizeFilter";
import filterSVG from "../../../images/svg/filter.svg";

interface ItemsTableProps {
  vendor?: VendorInfo;
  itemSelection: (item?: ItemData) => void;
}

const ItemsTable = (props: ItemsTableProps) => {
  const { vendor, itemSelection } = props;
  const { user } = useAuth();
  const gridRef = useRef<AgGridReact<ItemData>>(null);
  const isVendorUser = PermissionService.isVendorUser(user);
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const getCurrentSearch = () => {
    const itemQuery: string = localStorage.getItem("ItemSearchQuery") ?? "";
    const itemArray = itemQuery.match(/q=.*/);
    return itemQuery && itemArray
      ? itemArray[0].split("=")[1]
      : generateQueryParams(searchParams).query;
  };

  const [itemSearchInput, setItemSearchInput] = useState(getCurrentSearch());
  const itemServerSideDatasource = useMemo(() => {
    const dataSource = new ItemServerSideDatasource(gridRef, vendor);
    dataSource.searchText = itemSearchInput ?? "";
    return dataSource;
  }, [itemSearchInput, vendor]);

  const getColumnDefs = useCallback(() => {
    const userDefs: (ColDef<ItemData> | ColGroupDef<ItemData>)[] = isVendorUser
      ? [DescriptionColDefsForVendorUsers]
      : [DescriptionColDefsForCostcoUsers, CategoriesColDefs];

    const defaultDefs: (ColDef<ItemData> | ColGroupDef<ItemData>)[] = [
      DepartmentColDef,
      VendorBBAColDef,
      VendorNameColDef,
      ...userDefs,
      CertificationsColDefs,
      CommoditiesColDefs,
      OrganicColDef,
      PalmOilColDef,
    ];

    return [...BBAVendorItemTableColumns, ...defaultDefs];
  }, [isVendorUser]);

  const columnDefs = useMemo(() => getColumnDefs(), [getColumnDefs]);

  const handleCheckboxListToggle = () => {
    setIsOpen(!isOpen);
  };

  const onItemRowClicked = useCallback(
    (event: RowClickedEvent<ItemData>) => {
      const selectedItem = event.data;
      itemSelection(selectedItem);
    },
    [itemSelection],
  );

  const getItemTable = useMemo(() => {
    return (
      <DefaultGrid<ItemData>
        rowId={(params) => params.data._id ?? ""}
        datasource={itemServerSideDatasource}
        columnDefs={columnDefs}
        onRowClicked={onItemRowClicked}
        noRowsFoundText="No Items Found"
        ref={gridRef}
      />
    );
  }, [columnDefs, itemServerSideDatasource, onItemRowClicked]);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex w-full">
          <button
            type="button"
            className="flex"
            onClick={handleCheckboxListToggle}
          >
            <div className="self-center justify-items-start bg-blue w-[24px] h-[24px] rounded-full">
              <img
                src={filterSVG as string}
                alt="Filter"
                className="bg-blue inline-block rounded-full w-[18px] h-[18px]"
              />
            </div>
            <p className="inline-block text-blue px-4">Customize Column View</p>
          </button>
        </div>
        <SearchBar
          value={itemSearchInput ?? ""}
          placeholder="Search by Item Number or Description"
          setValue={setItemSearchInput}
          searchType="items"
        />
      </div>
      {isOpen && gridRef.current && (
        <ColumnCustomizeFilter
          gridApi={gridRef.current.api}
          onClickOutside={() => setIsOpen(false)}
        />
      )}
      <div className="shadow mt-8 mb-8">{getItemTable}</div>
    </>
  );
};
export default ItemsTable;
