import { ReactElement } from "react";
import ItemData from "../../api/interface/cdh/ItemData";
import Category from "../../api/interface/cdh/Category";

const ItemBreadcrumbs = (props: { item: ItemData }): ReactElement => {
  const getCategoryString = (cat: Category | undefined) => {
    if (cat) {
      return `${cat.code} - ${cat.description}`;
    }
    return "";
  };

  const { item } = props;
  const { department } = item;
  const breadcrumbs = [
    department?.id?.toString() ?? "",
    getCategoryString(department?.category1),
    getCategoryString(department?.category2),
    getCategoryString(department?.category3),
  ].filter((cat) => cat.length > 0);
  return (
    <nav
      className="pt-6 flex justify-between text-blue-700 text-xs"
      aria-label="Breadcrumb"
    >
      <ol className="mb-3 inline-flex items-center space-x-1">
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <li aria-current="page" key={`breadcrumb-${breadcrumb}`}>
              <div className="flex items-center">
                {index > 0 && <div className="text-gray-400 px-2">/</div>}
                <div className="font-medium text-gray-500">{breadcrumb}</div>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default ItemBreadcrumbs;
