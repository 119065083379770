import { ReactElement } from "react";
import StepperItem from "./StepperItem";

export interface StepperControlProps {
  name: string;
  steps: string[];
  enabled: boolean[];
  currentStep: number;
  stepFunction: (step: number) => void;
}

const StepperControl = (props: StepperControlProps): ReactElement => {
  const { name, steps, currentStep, enabled, stepFunction } = props;
  const stepCount = steps.length;

  return (
    <ol className="mb-8 flex w-full items-center text-center text-sm font-medium text-gray-400 sm:text-base">
      {steps.map((step, index) => {
        return (
          <StepperItem
            key={`stepper-${name}-${step}`}
            enabled={enabled[index]}
            text={step}
            currentStep={currentStep}
            stepNumber={index + 1}
            stepFunction={stepFunction}
            totalSteps={stepCount}
          />
        );
      })}
    </ol>
  );
};

export default StepperControl;
