import { ReactElement } from "react";
import { useLocation } from "react-router-dom";

interface LinkItemProps {
  href: string;
  label: string;
  id?: string;
  additionalClasses?: string;
}

const LinkItem = (props: LinkItemProps): ReactElement => {
  const { href, label, id, additionalClasses } = props;
  const location = useLocation();
  const focusStyle = "font-bold";
  const isFocused = location.pathname === href;
  return (
    <a
      href={href}
      tabIndex={0}
      id={id ?? "LinkItem"}
      className={`px-4 text-CostcoAction h-full content-center ${additionalClasses ?? ""} ${isFocused ? "border-b-2 -mb-[2px] border-CostcoAction" : ""}  hover:border-b-2 hover:-mb-[2px] hover:border-CostcoAction`}
    >
      <p className={`${isFocused ? focusStyle : ""} `}>{label}</p>
    </a>
  );
};

export default LinkItem;
