import { FileIcon, defaultStyles } from "react-file-icon";

const FileIconUtil = (props: { extension: string }) => {
  const { extension } = props;

  // @ts-expect-error external library typescript incompatibility
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <FileIcon extension={extension} {...defaultStyles[extension]} />;
};

export default FileIconUtil;
