import { ReactElement } from "react";

export interface StepperItemProps {
  text: string;
  currentStep: number;
  stepNumber: number;
  enabled: boolean;
  totalSteps: number;
  stepFunction: (step: number) => void;
}

const StepperItem = (props: StepperItemProps): ReactElement => {
  const { text, currentStep, enabled, stepNumber, totalSteps, stepFunction } =
    props;
  const isCurrentOrPrevious: boolean = stepNumber <= currentStep;
  const isLastStep: boolean = totalSteps === stepNumber;

  const jumpToStep = () => {
    if (enabled) {
      stepFunction(stepNumber);
    }
  };

  const itemStyles = ["flex items-center"];
  if (!isLastStep)
    itemStyles.push(
      "after:border-1 after:mx-6 after:hidden after:h-1 after:w-full after:border-b sm:after:inline-block sm:after:content-[''] md:w-full xl:after:mx-10",
    );

  let marker = <span className="me-2">{stepNumber}</span>;
  if (isCurrentOrPrevious) {
    itemStyles.push("text-blue-800 after:border-blue-800");
    marker = <span className="material-icons">check_circle</span>;
  } else {
    itemStyles.push("text-gray-600 after:border-gray-500");
  }

  if (enabled) {
    itemStyles.push("cursor-pointer");
  }

  return (
    <li className={itemStyles.join(" ")}>
      <h3>
        <button
          type="button"
          onClick={jumpToStep}
          className={[
            "flex items-center after:mx-2 after:text-gray-700 after:content-['/'] sm:after:hidden select-none",
            enabled ? "hover:text-blue-800" : "",
          ].join(" ")}
        >
          {marker}
          <span
            className={[
              "hidden whitespace-nowrap sm:ms-2 sm:inline-flex",
              enabled ? "hover:text-blue-800 hover:underline" : "",
            ].join(" ")}
          >
            {text}
          </span>
        </button>
      </h3>
    </li>
  );
};

export default StepperItem;
