enum Role {
  COSTCO_CONTENTMGMT_CREATOR = "Content Management Creator",
  COSTCO_CONTENTMGMT_ADMIN = "Content Management Admin",
  COSTCO_VENDORROLODEX_READONLY = "Vendor Rolodex Read Only",
  COSTCO_ITEMROLODEX_READONLY = "Item Rolodex Read Only",
  COSTCO_VENDORPROFILE_READONLY = "Vendor Profile Read Only",
  COSTCO_VENDORPROFILE_ADMIN = "Vendor Profile Admin",
  COSTCO_USER = "Costco Employee",

  VENDOR_ADMIN = "Vendor Admin",
  VENDOR_ESG_LEAD = "ESG Lead",
  VENDOR_SUSTAINABILITY_USER = "Vendor Sustainability User",
}

export const VendorSearchRoles: Role[] = [
  Role.VENDOR_ADMIN,
  Role.VENDOR_ESG_LEAD,
  Role.COSTCO_VENDORROLODEX_READONLY,
  Role.COSTCO_VENDORPROFILE_ADMIN,
];

export const VendorRoles: Role[] = [
  Role.VENDOR_ADMIN,
  Role.VENDOR_ESG_LEAD,
  Role.VENDOR_SUSTAINABILITY_USER,
];

export default Role;
