import ContentStatus from "../../api/interface/ContentStatus";
import PageContent from "../../api/interface/PageContent";

const StatusChip = (props: { content: PageContent }) => {
  const { content } = props;
  let colorStyle: string = "";
  let statusText = "";

  switch (content.status) {
    case ContentStatus.ACTIVE:
      statusText = "Live";
      colorStyle = " bg-success-background text-success-text ";
      break;
    case ContentStatus.ARCHIVE:
      statusText = "Archived";
      colorStyle = " bg-gray-300 text-gray-700 ";
      break;
    case ContentStatus.DRAFT:
      statusText = "Draft";
      colorStyle = " bg-caution-background text-caution-text ";
      break;
    default:
  }

  return (
    <span
      className={[
        "items-center font-sans font-semibold uppercase whitespace-nowrap select-none py-1 px-4 text-xs rounded-md",
        colorStyle,
      ].join(" ")}
    >
      {statusText}
    </span>
  );
};

export default StatusChip;
