import { ReactElement, MouseEvent, FC } from "react";

type CubeProps = {
  name: string;
  additionalClasses?: string;
  onDelete?: (role: string) => void;
};
const Cube: FC<CubeProps> = (props: CubeProps): ReactElement => {
  const { name, additionalClasses, onDelete } = props;
  const handleDelete = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    return onDelete && onDelete(name);
  };
  return (
    <div
      className={`inline-flex p-2 mx-1 rounded bg-gray-950/80 text-sm text-white ${additionalClasses ?? " "}`}
    >
      {name}
      <button
        type="button"
        onClick={handleDelete}
        className="relative ml-2 mt-[2px]"
      >
        <span className="material-icons-outlined text-xs">close</span>
      </button>
    </div>
  );
};
export default Cube;
