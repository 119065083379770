import { RefObject } from "react";
import { AgGridReact } from "ag-grid-react";
import { AxiosResponse } from "axios";
import { IGetRowsParams } from "ag-grid-community";
import { GridPaginatedResponse } from "../interface/GridPaginatedResponse";

const handleGridPaginatedResponse = <T,>(gridRef: RefObject<AgGridReact<T> | null>, params: IGetRowsParams) => (response: AxiosResponse<GridPaginatedResponse<T>>) => {
  if (response.data) {
    const { items, size } = response.data;

    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      if (size && items?.length > 0) {
        gridApi.hideOverlay();
      } else {
        gridApi.showNoRowsOverlay();
      }
    }

    params.successCallback(items, size);
  }
  else {
    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      gridApi.showNoRowsOverlay();
    }
    params.successCallback([]);
  }
};

export default handleGridPaginatedResponse;
