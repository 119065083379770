import Axios from "axios";
import ActivityItem from "./interface/ActivityItem";

export default class ActivitiesApi {
  public static async get(): Promise<ActivityItem[]> {
    try {
      const response = await Axios.get("/activities");
      return response.data.activities;
    } catch (_exception) {
      return [];
    }
  }
}
