import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ApiSettings from "./api/ApiSettings";
import { AuthProvider } from "./context/AuthContext";
import { ModalContextProvider } from "./context/ModalContext";
import AppSettingsService from "./api/AppSettingsService";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-phone-number-input/style.css";

await AppSettingsService.fetchAndSetAppSettings();
ApiSettings.initialize();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <AuthProvider>
    <ModalContextProvider>
      <App />
    </ModalContextProvider>
  </AuthProvider>,
);
