const SearchErrorPrompt = ({
  dismiss,
  errorTitle,
  errorMsg,
}: {
  dismiss: () => void;
  errorTitle?: string;
  errorMsg?: string;
}) => {
  return (
    <div className="text-center bg-red-100 text-red-900 font-Helvetica flex flex-row h-15 w-5/6 py-4">
      <div className="material-icons-outlined font-semibold pr-3 pl-5 pt-4">
        error_outline
      </div>
      <div className="flex-item items-start p-1">
        <div className="font-medium w-full items-start text-start">
          {errorTitle ?? "Search Input Error"}
        </div>
        <div className="text-extralight text-sm pt-2 text-start">
          {errorMsg ??
            "Please check if the search text contains any special characters. If so, try searching by removing the special characters."}
        </div>
      </div>
      <div className="mt-[-13px] pl-1">
        <button
          type="button"
          className="text-lg font-semibold text-gray-700 material-icons-outlined cursor-pointer"
          onClick={dismiss}
        >
          close
        </button>
      </div>
    </div>
  );
};

export default SearchErrorPrompt;
