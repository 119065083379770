import svgLogo from "../../images/svg/logo.svg";

const renderHeader = () => (
  <header className="flex-none h-14 text-sm 2xl:h-[84px] 2xl:text-base border-b-2 bg-gray-150 border-gray-300 text-blue-600">
    <div
      id="header-content"
      className="flex flow-row items-center h-full justify-between"
    >
      <div id="branding-area" className="pl-4 flex-1">
        <a href="/" className="flex flex-row">
          <img src={svgLogo as string} className="h-8 mx-2" alt="Logo" />
        </a>
      </div>
    </div>
  </header>
);

export default renderHeader;
