import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AxiosProgressEvent } from "axios";
import CSS from "csstype";
import PageContent, {
  DEFAULT_PAGE_CONTENT,
} from "../api/interface/PageContent";
import StepperControl from "../components/stepper/StepperControl";
import FilePicker from "../components/common/FilePicker";
import StyledButton from "../components/common/StyledButton";
import useAuth from "../hooks/useAuth";
import ContentStatus from "../api/interface/ContentStatus";
import ContentApi from "../api/ContentApi";
import FileApi from "../api/FileApi";
import ContentView from "../components/content/ContentView";
import FileInfo from "../util/FileInfo";
import PermissionService from "../permissions/PermissionService";
import { useConfirmationModalContext } from "../context/ModalContext";
import AttributePicker from "../components/content/AttributePicker";
import { getCurrentLocalDate } from "../util/Utils";

export default function CreateContentPage(): ReactElement {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const modalContext = useConfirmationModalContext();

  const [pageContent, setPageContent] = useState<PageContent>();
  const [isNew, setIsNew] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<number>(
    pageContent === undefined ? 1 : parseInt(params.step || "4", 10),
  );

  // File or URL values from step 1.
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState<string>();
  const [fileMetadata, setFileMetadata] = useState<FileInfo>();
  const [fileUploadPercentage, setFileUploadPercentage] = useState<number>(0);
  const [contentUploading, setContentUploading] = useState<boolean>(false);

  // Thumbnail or URL values from step 2.
  const [thumbnail, setThumbnail] = useState<File>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [thumbnailMetadata, setThumbnailMetadata] = useState<FileInfo>();

  const contentEditable: boolean =
    (pageContent === undefined ||
      pageContent.status === ContentStatus.NEW ||
      pageContent.status === ContentStatus.DRAFT) &&
    PermissionService.hasCreatorRole(user);

  useEffect(() => {
    if (params.id! === "new") {
      return;
    }
    ContentApi.getItem(params.id!).then((content) => {
      if (content) {
        setPageContent(content);
        setCurrentStep(4);
        const metadata = new FileInfo(user!);
        metadata.setName(content.title ?? "");
        metadata.setLink(content.link ?? "");
        setFileMetadata(metadata);
        const thumbMetadata = new FileInfo(user!, undefined);
        thumbMetadata.setLink(content.image ?? "");
        setThumbnailMetadata(thumbMetadata);
        setIsNew(false);
        setIsDirty(false);
        setFileUploadPercentage(100);
      }
    });
  }, [params.id, user]);

  const close = async () => {
    const confirm = isDirty
      ? await modalContext.showConfirmation(
          "Discard changes",
          <div>
            Are you sure you want to navigate away from this page?
            <br />
            All pending changes will be lost.
          </div>,
          "Discard changes",
        )
      : true;
    if (!confirm) {
      return;
    }
    navigate({ pathname: `/content`, search: searchParams.toString() });
  };

  const handleContentChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (pageContent) {
      const { name, value } = e.target;
      const newState: PageContent = {
        ...pageContent,
        [name]: value,
      };
      setIsDirty(!isNew);
      setPageContent(newState);
    }
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    const progress = Math.floor((progressEvent.progress || 0) * 100);
    setFileUploadPercentage(progress);
    if (progress === 100) {
      setContentUploading(false);
    }
  };

  const updateContent = (link: string | undefined) => {
    const newState: PageContent = {
      ...pageContent,
      name: pageContent?.name ?? v4(),
      title: file?.name ?? "",
      subtitle: file?.name ?? "",
      author: pageContent?.author ?? (user?.givenName as string),
      date: pageContent?.date ?? (getCurrentLocalDate() as string),
      status: pageContent?.status ?? ContentStatus.NEW,
      link,
    };
    setIsDirty(!isNew);
    setPageContent(newState);
  };

  const loadFromFile = () => {
    if (!file) return;

    setContentUploading(true);
    const metadata = new FileInfo(user!, file);
    setFileMetadata(metadata);
    updateContent(undefined);

    metadata.upload(onUploadProgress).then((link) => updateContent(link));
  };

  const loadFromUrl = async () => {
    // load metadata for URL
    let pc = await ContentApi.loadMetadata(url!);
    if (!pc) {
      pc = {
        name: pageContent?.name || v4(),
        status: pageContent?.status ?? ContentStatus.NEW,
      };
    } else {
      pc.status = pageContent?.status ?? ContentStatus.NEW;
    }

    pc.title = pc.title ?? url!.split("/").pop() ?? "";
    pc.author = pc.author ?? (user?.givenName as string);
    pc.date = pc.date ?? (getCurrentLocalDate() as string);
    pc.type = pageContent?.type ?? undefined;

    const metadata = new FileInfo(user!, undefined);
    metadata.setName(pc.title);
    metadata.setLink(url!);

    const thumbMetadata = new FileInfo(user!, undefined);
    thumbMetadata.setLink(pc.image ?? "");
    setThumbnailMetadata(thumbMetadata);
    setFileMetadata(metadata);
    setIsDirty(!isNew);
    setPageContent(pc);
  };

  const uploadFile = async () => {
    if (pageContent && fileMetadata) {
      return;
    }

    if (file) {
      loadFromFile();
    } else if (url) {
      loadFromUrl().then();
    }
  };

  const updateFileOrUrl = (f: File | undefined, link: string | undefined) => {
    setFile(f);
    setUrl(link);
    setFileMetadata(undefined);
    if (f && !contentUploading) {
      uploadFile().then();
    }
  };

  const uploadThumbnail = async () => {
    const pc = pageContent!;
    if (pageContent && thumbnailMetadata) {
      return;
    }

    if (thumbnail) {
      pc.image = await FileApi.uploadPageContent(user!, thumbnail);
    } else {
      // load metadata for URL
      pc.image = thumbnailUrl ?? "";
    }
    const metadata = new FileInfo(user!, undefined);
    metadata.setLink(pc.image ?? "");
    setThumbnailMetadata(metadata);
    setIsDirty(!isNew);
    setPageContent(pc);
  };

  const updateThumbnail = (f: File | undefined, link: string | undefined) => {
    setThumbnail(f);
    setThumbnailUrl(link);
    setThumbnailMetadata(undefined);
  };

  const getTitle = () => {
    if (isNew) {
      return "Create Content";
    }
    const contentTitle = pageContent?.title;
    if (contentTitle) {
      return <span className="text-xl">{`${contentTitle}`}</span>;
    }
    return contentEditable ? "Edit Content" : "View Content";
  };

  const jumpToStep = (newStep: number) => {
    if (currentStep === newStep) return;

    if (currentStep === 1) {
      uploadFile().then(() => setCurrentStep(newStep));
    } else if (currentStep === 2) {
      uploadThumbnail().then(() => setCurrentStep(newStep));
    } else {
      setCurrentStep(newStep);
    }
  };

  const renderSelectedContent = () => {
    if (fileMetadata === undefined) {
      return (
        <FilePicker useImageMode={false} selectionChanged={updateFileOrUrl} />
      );
    }

    const barStyle: CSS.Properties = {
      width: `${fileUploadPercentage}%`,
    };

    const textColor: string =
      fileUploadPercentage > 49 ? "text-white" : "text-gray-800";
    let bgColor = "bg-blue-500/80";
    let uploadText = `${fileUploadPercentage}%`;
    if (fileUploadPercentage === 100) {
      bgColor = "bg-green-500";
      uploadText = `File upload successful`;
    }

    return (
      <div className="relative flex w-full items-center justify-center">
        {contentEditable && (
          <button
            type="button"
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 cursor-pointer"
            onClick={() => updateFileOrUrl(undefined, undefined)}
          >
            <span className="material-icons-outlined">close</span>
          </button>
        )}
        {fileMetadata.renderPreview()}
        <div className="absolute bottom-4 w-full h-8">
          <div className="mx-8 bg-gray-500 rounded-lg relative">
            <div
              className={`absolute w-full text-center text-xs py-1 font-bold ${textColor}`}
            >
              {uploadText}
            </div>
            {/* eslint-disable-next-line react/forbid-dom-props */}
            <div className={`${bgColor} rounded-lg`} style={barStyle}>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContentPicker = () => (
    <section
      key="step-1"
      className={[
        "basis-full grow-0 shrink-0 transition-all duration-150",
        currentStep !== 1 ? "opacity-0" : "",
      ].join(" ")}
    >
      {renderSelectedContent()}
      <div className="my-8 flex justify-end duration-150">
        <StyledButton
          text="Next"
          disabled={contentUploading || (!(file && fileMetadata) && !url)}
          onClick={() => uploadFile().then(() => jumpToStep(currentStep + 1))}
        />
      </div>
    </section>
  );

  const renderThumbnailPicker = () => (
    <section
      key="step-2"
      className={[
        "basis-full grow-0 shrink-0 transition-all",
        currentStep !== 2 ? "opacity-0" : "",
      ].join(" ")}
    >
      {thumbnailMetadata && (
        <div className="relative flex w-full items-center justify-center">
          {contentEditable && (
            <button
              type="button"
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 cursor-pointer"
              onClick={() => updateThumbnail(undefined, undefined)}
            >
              <span className="material-icons-outlined">close</span>
            </button>
          )}
          {thumbnailMetadata.renderPreview(true)}
        </div>
      )}
      {thumbnailMetadata === undefined && (
        <FilePicker useImageMode selectionChanged={updateThumbnail} />
      )}
      <div className="my-8 flex">
        <StyledButton
          text="Previous"
          onClick={() => jumpToStep(currentStep - 1)}
        />
        <div className="flex-grow" />
        <StyledButton
          text="Next"
          onClick={() =>
            uploadThumbnail().then(() => jumpToStep(currentStep + 1))
          }
        />
      </div>
    </section>
  );

  const render = () => {
    let steps = ["Select Content", "Select Thumbnail", "Edit Info", "Preview"];
    if (!isNew) {
      steps = [
        contentEditable ? "Edit Content" : "View Content",
        contentEditable ? "Edit Thumbnail" : "View Thumbnail",
        contentEditable ? "Edit Info" : "View Info",
        "Preview",
      ];
    }

    const optionalStepsEnabled =
      url !== undefined || fileMetadata !== undefined;
    const stepsEnabled = [
      true,
      optionalStepsEnabled,
      optionalStepsEnabled,
      optionalStepsEnabled,
    ];

    const sections = [
      renderContentPicker(),
      renderThumbnailPicker(),
      <AttributePicker
        key="create-item-step-3"
        stepId={3}
        currentStep={currentStep}
        pageContent={pageContent ?? DEFAULT_PAGE_CONTENT}
        contentChangedAction={handleContentChanged}
        stepAction={jumpToStep}
      />,
      <ContentView
        key="create-item-step-4"
        stepId={4}
        currentStep={currentStep}
        pageContent={pageContent ?? DEFAULT_PAGE_CONTENT}
        contentChangedAction={handleContentChanged}
        isModified={isDirty}
        stepAction={jumpToStep}
      />,
    ];

    return (
      <div className="flex-grow self-center w-full max-w-8xl px-4 md:px-16 box-border">
        <div id="main-wrapper" className="w-full text-left relative">
          <div
            role="presentation"
            className="relative w-full h-full bg-white overflow-x-hidden"
          >
            <button
              type="button"
              className="absolute top-8 right-0 text-gray-500 hover:text-gray-800 cursor-pointer"
              onClick={close}
            >
              <span className="material-icons-outlined">close</span>
            </button>
            <h1 className="pt-6 pb-8 text-2xl font-semibold text-gray-700">
              <span>{getTitle()}</span>
            </h1>
            <StepperControl
              name="createItemStepper"
              steps={steps}
              enabled={stepsEnabled}
              currentStep={currentStep}
              stepFunction={jumpToStep}
            />
            <div
              className={[
                "flex transition-all duration-500",
                currentStep === 2 ? "-translate-x-[100%]" : "",
                currentStep === 3 ? "-translate-x-[200%]" : "",
                currentStep === 4 ? "-translate-x-[300%]" : "",
                currentStep === 5 ? "-translate-x-[400%]" : "",
                currentStep === 6 ? "-translate-x-[500%]" : "",
                currentStep === 7 ? "-translate-x-[600%]" : "",
                currentStep === 8 ? "-translate-x-[700%]" : "",
                currentStep === 9 ? "-translate-x-[800%]" : "",
                currentStep === 10 ? "-translate-x-[900%]" : "",
              ].join(" ")}
            >
              {sections.map((s) => s)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
}
