import QueryParams from "../api/interface/QueryParams";

export const generateQueryParams = (params: URLSearchParams, cleanQuery?:boolean): QueryParams => ({
  query:  !cleanQuery ? params.get("q") || undefined : "",
  itemsPerPage: parseInt(params.get("s") ?? "50", 10),
  pageNumber: parseInt(params.get("p") ?? "1", 10),
  vendorType: params.get("vt") ?? "bba",
  sortBy: params.get("sortBy") ?? "sapNumber",
});

export const getSearchParams = (qp: QueryParams): string[] => {
  const search: string[] = [];

  if (qp.vendorType === "ap" && qp.sortBy === "bbaNumber") {
    qp.sortBy = "name";
  }

  search.push(
      `p=${qp.pageNumber}`,
      `s=${qp.itemsPerPage}`,
      `sortBy=${qp.sortBy}`,
  );
  // Add filters
  search.push(`vt=${qp.vendorType}`);

  if (qp.query?.length) {
    search.push(`q=${qp.query}`);
  }
  return search;
};

export const capitalizeFirstLetter = (string?: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

const text = () => {
}

export default text;
