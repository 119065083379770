import ColumnDefinition from "../tables/ColumnDefinition";
import PageContent from "../../api/interface/PageContent";
import StatusChip from "./StatusChip";
import ContentActionBar from "./ContentActionBar";
import ContentType from "../../api/interface/ContentType";
import FileIconUtil from "../../util/FileIconUtil";
import ImageErrorFunction from "../../util/ImageErrorFunction";

const getImage = (item: PageContent) => {
  if (item.type === ContentType.DOCUMENTS) {
    if (!URL.canParse(item.link ?? "")) {
      return <div />;
    }
    const itemUrl = new URL(item.link ?? "");
    const fileEnd = itemUrl.pathname.split(".").pop() ?? "";
    const extension = fileEnd.toLowerCase();
    return (
      <div className="w-16 h-16 pl-4 pt-2 mt-1 mb-2">
        <FileIconUtil extension={extension} />
      </div>
    );
  }
  return (
    <img
      className="m-2 h-16 w-16 object-cover"
      alt="Content"
      src={item.image ?? "#"}
      onError={ImageErrorFunction}
    />
  );
};

const IMAGE_COLUMN: ColumnDefinition<PageContent> = {
  name: "Image",
  styles: "min-w-20 min-h-20",
  sortable: false,
  hideHeaderText: true,
  value: getImage,
};

const TITLE_COLUMN: ColumnDefinition<PageContent> = {
  name: "Title",
  styles: "p-4 border-b border-blue-gray-50 min-w-[198px]",
  sortable: true,
  sortValue: "title",
  value: (data: PageContent) => {
    return (
      <div className="flex items-center gap-3">
        <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
          {data.title}
        </p>
      </div>
    );
  },
};

const AUTHOR_COLUMN: ColumnDefinition<PageContent> = {
  name: "Author",
  sortable: true,
  sortValue: "author",
  value: (data: PageContent) => {
    return data.author ?? "";
  },
};

const DATE_COLUMN: ColumnDefinition<PageContent> = {
  name: "Date",
  sortable: true,
  sortValue: "date",
  value: (data: PageContent) => {
    return data.date ?? "";
  },
};

const STATUS_COLUMN: ColumnDefinition<PageContent> = {
  name: "Status",
  sortable: true,
  sortValue: "status",
  value: (data: PageContent) => {
    return <StatusChip content={data} />;
  },
};

const TYPE_COLUMN: ColumnDefinition<PageContent> = {
  name: "Type",
  sortable: true,
  sortValue: "type",
  value: (data: PageContent) => (
    <span className="capitalize">{data.type?.toLowerCase()}</span>
  ),
};

const ACTIONS_COLUMN: ColumnDefinition<PageContent> = {
  name: "Actions",
  sortable: false,
  value: (data: PageContent) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className="inline text-gray-600 select-none"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <ContentActionBar pageContent={data} useMiniBar />
    </span>
  ),
};

const DETAILS_COLUMN: ColumnDefinition<PageContent> = {
  name: "Details",
  styles: "p-4 border-b border-blue-gray-50 min-w-[198px]",
  sortable: true,
  sortValue: "title",
  value: (data: PageContent) => (
    <div className="flex-row gap-3">
      <p className="block antialiased font-sans text-sm leading-normal text-blue-gray-900 font-bold">
        {data.title}
      </p>
      <div className="text-sm break-normal">{data.subtitle}</div>
    </div>
  ),
};

const ContentTableColumnMap = new Map<string, ColumnDefinition<PageContent>>([
  ["image", IMAGE_COLUMN],
  ["title", TITLE_COLUMN],
  ["author", AUTHOR_COLUMN],
  ["date", DATE_COLUMN],
  ["status", STATUS_COLUMN],
  ["type", TYPE_COLUMN],
  ["actions", ACTIONS_COLUMN],
  ["details", DETAILS_COLUMN],
]);

const ContentTableColumns: ColumnDefinition<PageContent>[] = [
  ContentTableColumnMap.get("image")!,
  ContentTableColumnMap.get("title")!,
  ContentTableColumnMap.get("author")!,
  ContentTableColumnMap.get("date")!,
  ContentTableColumnMap.get("status")!,
  ContentTableColumnMap.get("type")!,
  ContentTableColumnMap.get("actions")!,
];

export const DocumentTableColumns: ColumnDefinition<PageContent>[] = [
  ContentTableColumnMap.get("image")!,
  ContentTableColumnMap.get("details")!,
  ContentTableColumnMap.get("author")!,
  ContentTableColumnMap.get("date")!,
];

export default ContentTableColumns;
