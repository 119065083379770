import { ReactElement } from "react";

type HeaderCellProps = {
  title: string;
  isFirstItem?: boolean;
  isSortable?: boolean;
  sortByColumn?: string;
  sortByValue?: string;
  sortDescending?: boolean;
  sortFunction: (columnName: string) => void;
  hideHeaderText?: boolean;
  alignFirstCenter?: boolean;
};

const HeaderCell = (props: HeaderCellProps): ReactElement => {
  const renderSortArrow = (isHidden?: boolean) => {
    const { isSortable, sortDescending, sortByColumn, sortByValue } = props;
    if (isSortable) {
      const isSelected =
        !isHidden && sortByColumn?.toLowerCase() === sortByValue?.toLowerCase();
      const direction =
        !isSelected || !sortDescending ? "arrow_upward" : "arrow_downward";
      const currentOpacity = isSelected ? "opacity-100" : "opacity-0";
      const classNames = [
        isHidden ? "group-hover:opacity-1" : "group-hover:opacity-100",
        "transition-all duration-200 px-1 material-icons-outlined text-xs",
        currentOpacity,
      ].join(" ");
      return <span className={classNames}>{direction}</span>;
    }
    return "";
  };

  const {
    title,
    alignFirstCenter,
    isFirstItem,
    sortByColumn,
    sortByValue,
    isSortable,
    sortFunction,
    hideHeaderText,
  } = props;
  if (hideHeaderText) {
    return (
      <th className="my-4">
        <label className="hidden">{title}</label>
      </th>
    );
  }
  const isSelected = sortByColumn?.toLowerCase() === sortByValue?.toLowerCase();
  return (
    <th>
      <div
        className={[
          "border-blue-900/40 my-4 px-4 flex",
          isFirstItem ? "border-none" : "border-l justify-center",
          alignFirstCenter ? "justify-center" : "",
        ].join(" ")}
      >
        <button
          type="button"
          onClick={() => {
            if (isSortable) {
              sortFunction(sortByValue ?? "");
            }
          }}
          className={[
            "flex justify-center group select-none whitespace-nowrap antialiased font-sans text-xs text-gray-900 hover:text-blue-800 font-semibold uppercase tracking-wider",
            isFirstItem ? "" : "text-center",
            isSelected ? "text-blue-800" : "text-gray-900",
          ].join(" ")}
        >
          {!isFirstItem && renderSortArrow(true)}
          {title ?? ""}
          {renderSortArrow()}
        </button>
      </div>
    </th>
  );
};

export default HeaderCell;
