import ItemData from "../../api/interface/cdh/ItemData";
import ItemInfo from "../cdh/ItemInfo";

export type ItemDetailsSectionProps = {
  visible: boolean;
  closeFunction: () => void;
  item?: ItemData;
};

const ItemDetailsSection = (props: ItemDetailsSectionProps) => {
  const { item, closeFunction, visible } = props;
  const defaultStyling: string =
    "fixed top-0 left-0 h-screen w-screen overflow-x-hidden bg-black/25 z-10";

  return (
    <div
      role="presentation"
      className={[
        defaultStyling,
        visible ? "opacity-100" : "opacity-0 pointer-events-none",
      ].join(" ")}
      onClick={closeFunction}
    >
      <div
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={[
          "absolute w-[48rem] top-0 right-0 bg-white shadow-2xl h-full pt-8 px-10 transition-all delay-100 duration-200",
          visible ? "translate-x-0" : "translate-x-full",
        ].join(" ")}
      >
        <button
          type="button"
          className="absolute top-8 right-10 text-gray-950 cursor-pointer"
          onClick={closeFunction}
        >
          <span className="material-icons-outlined">close</span>
        </button>
        <h1 className="text-xl text-gray-700">Item Details</h1>
        <div className="w-[1200px]] h-full">
          {item && <ItemInfo data={item} />}
        </div>
      </div>
    </div>
  );
};

export default ItemDetailsSection;
