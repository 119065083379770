import { ReactElement } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import PermissionService from "./permissions/PermissionService";
import Role, { VendorSearchRoles } from "./permissions/interface/Role";

import LogoutPage from "./pages/LogoutPage";
import ContentResetPage from "./pages/ContentResetPage";
import DefaultLayout from "./layout/DefaultLayout";
import NotFoundPage from "./pages/NotFoundPage";
import useAuth from "./hooks/useAuth";
import NoAccessPage from "./pages/NoAccessPage";
import HomePage from "./pages/HomePage";
import ContentManagementPage from "./pages/ContentManagementPage";
import CreateContentPage from "./pages/CreateContentPage";
import ItemSearchPage from "./pages/ItemSearchPage";
import ItemDetailPage from "./pages/ItemDetailPage";
import VendorSearchPage from "./pages/VendorSearchPage";
import VendorDetailsPage from "./pages/VendorDetailsPage/VendorDetailsPage";
import TrainingAndEducationPage from "./pages/TrainingAndEducationPage/TrainingAndEducationPage";

const Router = (): ReactElement => {
  const { user } = useAuth();

  const getAdminRoutes = () => {
    return (
      <>
        <Route
          path="/content/reset"
          element={
            PermissionService.hasPermissionLevel(
              user,
              Role.COSTCO_CONTENTMGMT_ADMIN,
            ) ? (
              <ContentResetPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          path="/items"
          element={
            PermissionService.hasPermissionLevel(
              user,
              Role.COSTCO_ITEMROLODEX_READONLY,
              Role.VENDOR_ADMIN,
              Role.VENDOR_ESG_LEAD,
            ) ? (
              <ItemSearchPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route
          path="/vendors"
          element={
            PermissionService.hasPermissionLevel(user, ...VendorSearchRoles) ? (
              <VendorSearchPage />
            ) : (
              <NotFoundPage />
            )
          }
        />
        <Route element={<DefaultLayout />}>
          <Route path="/item/:id/:section" element={<ItemDetailPage />} />
          <Route
            path="/vendor/:sapNumber/:bbaNumber?/:section"
            element={<VendorDetailsPage />}
          />
        </Route>
      </>
    );
  };

  const getManagementRoutes = () => {
    const hasManagementPermissions = PermissionService.hasCreatorRole(user);
    if (!hasManagementPermissions) {
      return (
        <Route element={<DefaultLayout />}>
          <Route path="/content" element={<NotFoundPage />} />;
          <Route path="/content/item/:id" element={<NotFoundPage />} />
        </Route>
      );
    }
    return (
      <>
        <Route path="/content" element={<ContentManagementPage />} />
        <Route element={<DefaultLayout />}>
          <Route path="/content/item/:id" element={<CreateContentPage />} />
        </Route>
      </>
    );
  };

  const getUserRoutes = () => {
    const hasHomepagePermissions = PermissionService.hasPermissionLevel(
      user,
      ...Object.values(Role),
    );

    return (
      <Route>
        <Route element={<DefaultLayout />}>
          <Route
            path="/"
            element={hasHomepagePermissions ? <HomePage /> : <NoAccessPage />}
          />
        </Route>
        <Route
          path="/training-and-education"
          element={<TrainingAndEducationPage />}
        />
        <Route
          path="/t&e"
          element={<Navigate to="/training-and-education" />}
        />
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/logout" element={<LogoutPage />} />
        {getAdminRoutes()}
        {getManagementRoutes()}
        {getUserRoutes()}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
