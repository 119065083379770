import { ICellRendererParams } from "ag-grid-community";
import { useRef, useState } from "react";
import emailSVG from "../../../../images/svg/email.svg";
import Contact from "../../../../api/interface/Contact";
import Logger from "../../../../common/Logger";

const VAMContactActionCellRenderer = (
  params: ICellRendererParams<Contact, string>,
) => {
  const { value, node } = params;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isMailCopied, setIsMailCopied] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const onEmailClick = () => {
    if (!value) {
      Logger.logError("No email found to copy");
      return;
    }
    window.navigator.clipboard
      .writeText(value)
      .catch((error: Error) =>
        Logger.logError("Failed to copy to clipboard", error),
      );
  };

  const handleEmailClick = () => {
    onEmailClick();
    setIsMailCopied(true);
    tooltipRef.current?.focus();
  };
  const onMouseEnterEmailIcon = () => {
    setShowTooltip(true);
  };
  const onMouseLeaveEmailIcon = () => {
    setShowTooltip(false);
    setTimeout(() => {
      setIsMailCopied(false);
    }, 10000);
  };

  return (
    <div className="flex flex-row">
      <div
        className="font-xs flex-item"
        onMouseEnter={onMouseEnterEmailIcon}
        onMouseLeave={onMouseLeaveEmailIcon}
        id={`contact-action-email-icon-${node.id}`}
      >
        <button type="button" onClick={handleEmailClick}>
          <img
            className="size-5 mt-2 mx-1"
            src={emailSVG as string}
            alt="Email Icon"
          />
        </button>
        {showTooltip && (
          <div
            ref={tooltipRef}
            className="inline-block ag-row-position-absolute bg-gray-950/80 top-[1px] right-[2px] text-white h-5"
          >
            <p className="mt-[-10px] px-[5px] text-[12px]">
              {isMailCopied ? "Email Copied" : "Click to copy email"}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VAMContactActionCellRenderer;
