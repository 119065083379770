import { ChangeEvent } from "react";
import StyledInput from "../common/StyledInput";
import StyledButton from "../common/StyledButton";
import PageContent from "../../api/interface/PageContent";
import ContentStatus from "../../api/interface/ContentStatus";
import PermissionService from "../../permissions/PermissionService";
import useAuth from "../../hooks/useAuth";

export interface AttributePickerProps {
  stepId: number;
  currentStep: number;
  pageContent: PageContent;
  contentChangedAction: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  stepAction: (step: number) => void;
}

export default function AttributePicker(props: AttributePickerProps) {
  const { user } = useAuth();
  const { stepId, currentStep, pageContent, contentChangedAction, stepAction } =
    props;

  const contentEditable: boolean =
    (pageContent.status === ContentStatus.NEW ||
      pageContent.status === ContentStatus.DRAFT) &&
    PermissionService.hasCreatorRole(user);

  const render = () => (
    <section
      className={[
        "basis-full grow-0 shrink-0 transition-all",
        currentStep !== stepId ? "opacity-0" : "",
      ].join(" ")}
    >
      <StyledInput
        type="text"
        id="content-title"
        label="Title"
        name="title"
        readOnly={!contentEditable}
        value={pageContent?.title}
        onChange={contentChangedAction}
      />
      <StyledInput
        type="text"
        id="content-subtitle"
        label="Subtitle"
        name="subtitle"
        readOnly={!contentEditable}
        value={pageContent?.subtitle}
        onChange={contentChangedAction}
      />
      <StyledInput
        type="text"
        id="content-author"
        label="Author"
        name="author"
        readOnly={!contentEditable}
        value={pageContent?.author}
        onChange={contentChangedAction}
      />
      <StyledInput
        type="date"
        id="vendor-country"
        label="Date"
        name="date"
        readOnly={!contentEditable}
        value={pageContent?.date}
        onChange={contentChangedAction}
      />
      <div id="attributeSectionButtons" className="my-8 flex">
        <StyledButton
          text="Previous"
          onClick={() => stepAction(currentStep - 1)}
        />
        <div className="flex-grow" />
        <StyledButton text="Next" onClick={() => stepAction(currentStep + 1)} />
      </div>
    </section>
  );

  return render();
}
