import { ICellRendererParams } from "ag-grid-community";
import { useRef, useState } from "react";
import emailSVG from "../../../../images/svg/email.svg";
import editSVG from "../../../../images/svg/edit.svg";
import deleteSVG from "../../../../images/svg/delete.svg";
import Contact from "../../../../api/interface/Contact";

interface ContactActionRendererProps extends ICellRendererParams {
  onEditClick: (data: Contact) => void;
  onDeleteClick: (data: Contact) => void;
  onEmailClick: (data: Contact) => void;
  data: Contact;
}

const ContactActionCellRenderer = (params: ContactActionRendererProps) => {
  const { data, onEditClick, onDeleteClick, onEmailClick, node } = params;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [isMailCopied, setIsMailCopied] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const handleEdit = () => {
    onEditClick(data);
  };
  const handleDelete = () => {
    onDeleteClick(data);
  };
  const handleEmailClick = () => {
    onEmailClick(data);
    setIsMailCopied(true);
    tooltipRef.current?.focus();
  };
  const onMouseEnterEmailIcon = () => {
    setShowTooltip(true);
  };
  const onMouseLeaveEmailIcon = () => {
    setShowTooltip(false);
    setTimeout(() => {
      setIsMailCopied(false);
    }, 5000);
  };

  return (
    <div className="flex flex-row">
      <div
        className="font-xs flex-item"
        onMouseEnter={onMouseEnterEmailIcon}
        onMouseLeave={onMouseLeaveEmailIcon}
        id={`contact-action-email-icon-${node.id}`}
      >
        <button type="button" onClick={handleEmailClick}>
          <img
            className="size-5 mt-2 mx-1"
            src={emailSVG as string}
            alt="Email Icon"
          />
        </button>
        {showTooltip && (
          <div
            ref={tooltipRef}
            className="flex flex-row ag-row-position-absolute top-[2px] right-[-5px] text-white h-[20px] ml-[2px] "
          >
            <div className="h-[20px] w-0 bg-gray-950/80 relative">
              <div className="w-0 h-0 absolute border-solid border-y-[10px] border-y-white/0 border-r-[10px] border-r-gray-950/80 left-[-10px]" />
            </div>
            <div className="bg-gray-950/80 h-[20px] text-[12px]">
              <p className="mt-[-10px] mr-2">
                {isMailCopied ? "Email Copied" : "Click to copy email"}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex-item">
        <button type="button" onClick={handleEdit}>
          <img
            className="size-5 mt-2 mx-1"
            src={editSVG as string}
            alt="Edit Icon"
          />
        </button>
      </div>
      <div className="flex-item">
        <button type="button" onClick={handleDelete}>
          <img
            className="size-5 mt-2 mx-1"
            src={deleteSVG as string}
            alt="Delete Icon"
          />
          Delete
        </button>
      </div>
    </div>
  );
};

export default ContactActionCellRenderer;
