import { useNavigate } from "react-router-dom";
import errorIcon from "../images/svg/errorIcon.svg";
import StyledButton from "../components/common/StyledButton";
import StyledHeader from "../components/common/StyledHeader";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div>
      <StyledHeader />
      <div className="pt-12 bg-gray-100 text-gray-800 h-screen justify-center text-center">
        <div className="mx-auto">
          <div className="tracking-widest mt-4 text-CostcoAction font-Helvetica">
            <div className="flex justify-center items-center pt-28 pb-12">
              <img src={errorIcon as string} alt="errorIcon" />
            </div>
            <div className="text-5xl block">
              <p>We weren&apos;t able to find</p>
              <p>what you&apos;re looking for.</p>
            </div>
            <div className="ext-xl py-8">Page Not Found</div>
          </div>
          <StyledButton text="Go to HomePage" onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
