const MaintenancePage = () => {
  return (
    <div className="pt-12 bg-gray-100 text-gray-800 h-screen justify-center text-center">
      <div className="mx-auto max-w-4xl">
        <div className="tracking-widest mt-4">
          <span className="text-7xl block material-icons-outlined">
            construction
          </span>
          <span className="mt-2 text-2xl">Down for maintenance</span>
        </div>
        <div className="text-gray-700 font-mono text-base pt-6 rounded-md">
          The Sustainability Portal is currently down for maintenance and will
          return shortly.
        </div>
        <div className="mt-2 text-gray-700 font-mono text-xs rounded-md">
          In the meantime, how about checking out some great deals over at&nbsp;
          <a href="https://www.costco.com/CatalogSearch?keyword=OFF">
            Costco.com
          </a>
          ?
        </div>
      </div>
    </div>
  );
};

export default MaintenancePage;
