import { ReactElement } from "react";
import ColumnDefinition from "./ColumnDefinition";
import DataTableRow from "./DataTableRow";
import HeaderCell from "./HeaderCell";
import QueryParams from "../../api/interface/QueryParams";

type DataTableProps<Type> = {
  columns: Array<ColumnDefinition<Type>>;
  data: Array<Type>;
  getKey: (data: Type) => string;
  rowClicked: (data: Type) => void;
  totalItems: number;
  sortFunction: (columnName: string) => void;
  pageFunction: (pageNumber: number, itemsPerPage: number) => void;
  queryParams: QueryParams;
  alignFirstCenter?: boolean;
};

/**
 * The datatable class is a general handler for rendering tables of various types.
 * @constructor
 */
const DataTable = <Type,>(props: DataTableProps<Type>): ReactElement => {
  const renderHeader = () => {
    const { columns, sortFunction, queryParams, alignFirstCenter } = props;
    let firstVisibleColumn = true;
    return (
      <thead>
        <tr className="bg-gray-150 border-gray-300 border-y">
          {columns.map((column: ColumnDefinition<Type>) => {
            const name: string = column.name as string;
            const sortByValue: string = (column.sortValue ??
              column.name) as string;
            const sortable: boolean = (column.sortable as boolean) || false;
            const hideHeaderText: boolean =
              (column.hideHeaderText as boolean) || false;
            const isFirst = firstVisibleColumn;
            firstVisibleColumn = firstVisibleColumn && hideHeaderText;
            return (
              <HeaderCell
                key={`datatable-header-${column.name}`}
                title={name}
                isFirstItem={isFirst}
                alignFirstCenter={alignFirstCenter}
                isSortable={sortable}
                hideHeaderText={hideHeaderText}
                sortByColumn={queryParams.sortBy as string}
                sortByValue={sortByValue}
                sortDescending={queryParams.sortBy?.startsWith("-")}
                sortFunction={sortFunction}
              />
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderPaginationControls = () => {
    const { queryParams, totalItems, pageFunction } = props;
    const { pageNumber, itemsPerPage } = queryParams;

    const page = pageNumber === undefined ? 1 : pageNumber;
    const perPage = itemsPerPage === undefined ? 1000 : itemsPerPage;

    const firstItem = 1 + (page - 1) * perPage;
    const lastItem = Math.min(firstItem + perPage - 1, totalItems);

    const paginationDisabledStyle: string =
      "px-2 text-gray-500 cursor-pointer select-none";
    const paginationEnabledStyle: string =
      "px-2 text-blue-500 hover:text-blue-800 cursor-pointer select-none";
    const nextEnabled = lastItem < totalItems;
    const previousEnabled = page > 1;
    return (
      <div className="bg-white h-14 flex items-center text-gray-700 text-sm leading-none">
        <div className="flex pl-2">
          <div className="px-2">
            <span>Rows per page:</span>
          </div>
          <select
            value={itemsPerPage}
            onChange={(e) => pageFunction(page, parseInt(e.target.value, 10))}
            className="pb-2 border-b border-gray-700 hover:border-gray-900 focus:outline-none focus:ring-0 pr-8 pl-2 appearance-none"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div className="flex-grow">&nbsp;</div>
        <div className="flex leading-none items-center">
          <button
            type="button"
            className={
              previousEnabled ? paginationEnabledStyle : paginationDisabledStyle
            }
            onClick={() => {
              return previousEnabled && pageFunction(page - 1, perPage);
            }}
          >
            <span className="material-icons-outlined">navigate_before</span>
          </button>
          <div className="px-8">
            <span className="font-semibold">{firstItem}</span> to{" "}
            <span className="font-semibold">{lastItem}</span> of{" "}
            <span className="font-semibold">{totalItems}</span>
          </div>
          <button
            type="button"
            className={
              nextEnabled ? paginationEnabledStyle : paginationDisabledStyle
            }
            onClick={() => {
              return nextEnabled && pageFunction(page + 1, perPage);
            }}
          >
            <span className="material-icons-outlined">navigate_next</span>
          </button>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    const { columns, data, getKey, rowClicked } = props;
    return (
      <tbody className="bg-white">
        {data.map((item) => (
          <DataTableRow
            key={`datatable-row-${getKey(item)}`}
            id={getKey(item)}
            data={item}
            columns={columns}
            onClick={rowClicked}
          />
        ))}
      </tbody>
    );
  };

  return (
    <div className="shadow">
      <table className="w-full">
        {renderHeader()}
        {renderBody()}
      </table>
      {renderPaginationControls()}
    </div>
  );
};

export default DataTable;
