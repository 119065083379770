import { useNavigate } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Value as PhoneInputValue,
  ExternalValue as PhoneExternalValue,
} from "react-phone-number-input";
import { toast, ToastContainer } from "react-toastify";
import Contact from "../../api/interface/Contact";
import StyledInput from "../common/StyledInput";
import StyledButton from "../common/StyledButton";
import VendorApi from "../../api/VendorApi";

export type ContactSectionProps = {
  sapNumber: number;
  visible: boolean;
  contact?: Contact;
  closeFunction: () => void;
};

const ContactSection = (props: ContactSectionProps) => {
  const { sapNumber, contact, closeFunction, visible } = props;
  const [contactValues, setContactValues] = useState<Contact>({ sapNumber });
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState<PhoneInputValue | PhoneExternalValue>();
  const [sustainabilityRole, setSustainabilityRole] = useState("");
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const navigate = useNavigate();
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phoneValid, setPhoneValid] = useState<boolean>();
  const [sustainabilityValid, setSustainabilityValid] =
    useState<boolean>(false);
  const [showErrorPrompt, setShowErrorPrompt] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const defaultStyling: string =
    "fixed top-0 left-0 min-h-full w-screen overflow-x-hidden bg-black/25 z-10";
  const sustainabilityRoles = [
    "General Sustainability Contact",
    "Packaging",
    "Sourcing",
    "Animal Welfare",
    "Human Rights",
    "Climate",
  ];
  const checkNameFormat = (name: string) =>
    name.trim().length > 0 && /^[A-Za-zÀ-ÖØ-öø-ÿ_."' -]+$/.test(name);
  const checkEmailFormat = (emailString: string) => {
    const emailRegexr =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailString.length > 0 && emailRegexr.test(emailString);
  };
  const generateContact = () => {
    const newState = contactValues;
    newState.sapNumber = sapNumber;
    newState.firstName = firstName;
    newState.lastName = lastName;
    newState.email = email;
    newState.phone = phone || "";
    newState.sustainabilityRole = sustainabilityRole;
    return newState;
  };

  useEffect(() => {
    const errorMsg = localStorage.getItem("errorMessage");
    if (hasError && errorMsg && errorMsg.length > 0) {
      toast.error(errorMsg);
      localStorage.removeItem("errorMessage");
      setHasError(false);
    }
  }, [hasError]);

  useEffect(() => {
    if (!contact) {
      setIsNew(true);
      setSustainabilityRole("General Sustainability Contact");
    }
    if (contact) {
      setContactValues(contact);
      setFirstname(contact.firstName || "");
      setLastname(contact.lastName || "");
      setEmail(contact.email || "");
      setPhone(contact.phone || "");
      setSustainabilityRole(contact.sustainabilityRole || "");
      setIsNew(false);
    }
    setShowErrorPrompt(false);
  }, [isNew, sapNumber, contact]);

  useEffect(() => {
    const checkChanges = () =>
      [
        firstName !== contactValues.firstName,
        lastName !== contactValues.lastName,
        email !== contactValues.email,
        phone !== contactValues.phone,
        sustainabilityRole !== contactValues.sustainabilityRole,
      ].includes(true);
    if (
      checkChanges() &&
      firstName &&
      lastName &&
      email &&
      phone &&
      sustainabilityRole
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
    setFirstNameValid(checkNameFormat(firstName));
    setLastNameValid(checkNameFormat(lastName));
    setEmailValid(checkEmailFormat(email));
    setPhoneValid(!!(phone && phone.length > 2));
    setSustainabilityValid(
      !!(sustainabilityRole && sustainabilityRole.length > 0),
    );
  }, [contactValues, firstName, lastName, email, phone, sustainabilityRole]);

  const renderTitle = () => (
    <h1 className="text-xl">{isNew ? "Add Contact" : "Edit Contact"}</h1>
  );

  const saveChanges = async () => {
    const notValidateFormat = [
      firstNameValid,
      lastNameValid,
      emailValid,
      phoneValid,
      sustainabilityValid,
    ].includes(false);
    if (notValidateFormat) {
      setShowErrorPrompt(true);
    }
    if (!notValidateFormat) {
      const readyContact = generateContact();
      if (isNew) {
        // Todo: VAM contact dup check feature is postponed
        // const VamEmails: string =  resource;
        // if (VamEmails) {
        //   const emails: string[] = Object.values(JSON.parse(VamEmails));
        //   if (emails.length > 0) {
        //     if (readyContact.email && emails.includes(readyContact.email)) {
        //       setHasError(true);
        //       localStorage.setItem(
        //         "errorMessage",
        //         "ERROR: Contact is an existing Vendor User in Vendor Access Management!",
        //       );
        //       return;
        //     }
        //   }
        // }
        await VendorApi.createContact(readyContact)
          .then(() => {
            localStorage.setItem("successMessage", "Contact created");
            navigate(0);
          })
          .catch((err) => {
            localStorage.setItem("errorMessage", err.message);
            setHasError(true);
          });
      } else {
        await VendorApi.updateContact(readyContact)
          .then(() => {
            localStorage.setItem("successMessage", "Contact updated");
            navigate(0);
          })
          .catch((err) => {
            localStorage.setItem("errorMessage", err.message);
            setHasError(true);
          });
      }
    }
  };

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
  };
  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (value?: PhoneInputValue) => {
    setPhone(value);
  };
  const handleSustainabilityRoleChange = (target: string) => {
    setSustainabilityRole(target);
  };

  return (
    <div
      role="presentation"
      className={`min-h-screen overflow-y-scroll ${defaultStyling} ${visible ? "opacity-100" : "opacity-0 pointer-events-none"}`}
    >
      <div
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`h-auto absolute w-[36rem] top-0 right-0 bg-white shadow-2xl min-h-full pt-8 px-10 transition-all delay-100 duration-200"
          ${visible ? "translate-x-0" : "translate-x-full"}`}
      >
        <button
          type="button"
          className="absolute top-8 right-10 text-gray-950 cursor-pointer"
          onClick={closeFunction}
        >
          <span className="material-icons-outlined">close</span>
        </button>
        {renderTitle()}
        <StyledInput
          type="text"
          id="i1"
          label="First Name"
          name="i1n"
          isRequired
          maxLength={50}
          onChange={handleFirstNameChange}
          additionalClasses={
            showErrorPrompt && !firstNameValid ? "text-red border-red-500" : ""
          }
          value={firstName}
          formatError={showErrorPrompt && !firstNameValid}
        />
        {showErrorPrompt && !firstNameValid && (
          <label className="text-red text-xs">
            Please enter a valid first name
          </label>
        )}
        <StyledInput
          type="text"
          id="i1"
          label="Last Name"
          name="i1n"
          isRequired
          maxLength={50}
          onChange={handleLastNameChange}
          additionalClasses={
            showErrorPrompt && !lastNameValid ? "text-red border-red-500" : ""
          }
          value={lastName}
          formatError={showErrorPrompt && !lastNameValid}
        />
        {showErrorPrompt && !lastNameValid && (
          <label className="text-red text-xs">
            Please enter a valid last name
          </label>
        )}
        <StyledInput
          type="text"
          id="i2"
          label="Email"
          name="i2n"
          isRequired
          maxLength={50}
          onChange={handleEmailChange}
          additionalClasses={
            showErrorPrompt && !emailValid ? "text-red border-red-500" : ""
          }
          value={email}
          formatError={showErrorPrompt && !emailValid}
        />
        {showErrorPrompt && !emailValid && (
          <label className="text-red text-xs">
            Please enter a valid email address
          </label>
        )}
        <StyledInput
          type="text"
          id="i3"
          label="Phone Number"
          name="i3n"
          isRequired
          maxLength={15}
          onPhoneChange={handlePhoneChange}
          value={phone}
          additionalClasses={
            showErrorPrompt && !phoneValid ? "text-red border-red-500" : ""
          }
          formatError={showErrorPrompt && !phoneValid}
        />
        {showErrorPrompt && !phoneValid && (
          <label className="text-red text-xs">Please enter a valid phone</label>
        )}
        <StyledInput
          type="checkbox"
          id="i4"
          label="Sustainability Role"
          name="i4n"
          isRequired
          isCheckBox
          checkCollection={sustainabilityRoles}
          onCheckChange={handleSustainabilityRoleChange}
          value={sustainabilityRole}
          additionalClasses={
            showErrorPrompt && !sustainabilityValid
              ? "text-red border-red-500"
              : ""
          }
          formatError={showErrorPrompt && !sustainabilityValid}
        />
        {showErrorPrompt && !sustainabilityValid && (
          <label className="text-red text-xs">
            Please choose at least one Sustainability Role
          </label>
        )}
        <div className="space-x-2 float-right z-100">
          <StyledButton
            text="Cancel"
            isSecondaryAction
            onClick={closeFunction}
          />
          <StyledButton
            text="Save Changes"
            disabled={!hasChanged}
            onClick={saveChanges}
          />
        </div>
      </div>
      <div className="absolute bottom-4 w-full h-8">
        <div className="mx-8 bg-gray-500 rounded-lg relative">
          <div className="absolute w-full text-center text-xs py-1 font-bold text-gray" />
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default ContactSection;
