import {
  IDatasource,
  IGetRowsParams,
  SortModelItem,
} from "ag-grid-community";
import Axios from "axios";
import { GridPaginatedResponse } from "../interface/GridPaginatedResponse";
import Contact from "../interface/Contact";
import { AgGridReact } from "ag-grid-react";
import { RefObject } from "react";
import handleGridPaginatedResponse from "./GridPaginatedResponseHandler";

class ContactServerSideDatasource implements IDatasource {
  private _searchText: string = "";
  private readonly _sapNumber: number;
  private _gridRef: RefObject<AgGridReact<Contact> | null>;

  constructor(sapNumber: number, gridRef: RefObject<AgGridReact<Contact> | null>) {
    this._sapNumber = sapNumber;
    this._gridRef = gridRef;
  }

  // Implementation of the getRows method from IServerSideDatasource
  getRows(params: IGetRowsParams) {
    // Extract necessary parameters from params
    const { startRow, endRow, sortModel } = params;

    // Preparing query parameters for pagination, sorting, and filtering
    const queryParams = {
      startRow: startRow,
      endRow: endRow,
      ...this.getQueryParams(),
      ...this.parseSortModel(sortModel),
    };

    // Make an axios call to fetch data
    Axios.get<GridPaginatedResponse<Contact>>(`/contacts/vendor/${this._sapNumber}`, {
      params: queryParams,
    })
      .then(handleGridPaginatedResponse(this._gridRef, params))
      .catch((error) => {
        console.error("Error while fetching data from server:", error);
        params.failCallback();
      });
  }

  getQueryParams() {
    if (this._searchText) {
      return { search: this._searchText };
    }

    return {};
  }

  parseSortModel(sortModel: SortModelItem[]) {
    if (sortModel && sortModel.length > 0) {
      const { colId, sort } = sortModel[0];
      // If sort is ascending, no minus necessary before sort column
      const sortDir = sort === "asc" ? "" : "-";
      return { sortBy: `${sortDir}${colId}` };
    }
    return {};
  }
}

export default ContactServerSideDatasource;
