export default interface QueryParams {
  itemsPerPage?: number;
  pageNumber?: number;
  sortBy?: string;

  // Basic Query Params
  query?: string;

  // Content Query Params
  contentFilter?: string;
  statusFilter?: string;

  // Vendor Query Params
  vendorType?: string;
}

export const EMPTY_QUERY_PARAMS = {};

export const parseQueryParams = (queryParams: QueryParams) => {
  if (Object.keys(queryParams).length === 0) {
    return "";
  }

  const params: string[] = [];
  params.push(`p=${encodeURIComponent(queryParams.pageNumber ?? 1)}`);
  params.push(`s=${encodeURIComponent(queryParams.itemsPerPage ?? 1)}`);

  if (queryParams.query) {
    params.push(`q=${queryParams.query}`);
  }
  if (queryParams.sortBy) {
    const sortField = `${queryParams.sortBy}`;
    params.push(`sortBy=${encodeURIComponent(sortField)}`);
  }

  if (queryParams.contentFilter) {
    params.push(`content=${queryParams.contentFilter}`);
  }
  if (queryParams.statusFilter) {
    params.push(`status=${queryParams.statusFilter}`);
  }

  if (queryParams.vendorType) {
    params.push(`vt=${queryParams.vendorType}`);
  }

  return "?" + params.join("&");
};
