import React, { ReactElement, useContext, useRef, useState } from "react";
import StyledButton from "../components/common/StyledButton";

type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

type ModalContextType = {
  showConfirmation: (
    title: string,
    message: string | ReactElement,
    okText?: string,
    cancelText?: string,
  ) => Promise<boolean>;
};

type ModalContextProviderProps = {
  children: React.ReactNode;
};

const ModalContext = React.createContext<ModalContextType>(
  {} as ModalContextType,
);

export const ModalContextProvider: React.FC<ModalContextProviderProps> = (
  props,
) => {
  const { children } = props;
  const { setShow, onHide } = useModalShow();
  const [content, setContent] = useState<{
    title: string;
    message: string | ReactElement;
    okText: string;
    cancelText: string;
  } | null>();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const resolver = useRef<Function>();

  const handleShow = (
    title: string,
    message: string | ReactElement,
    okText?: string,
    cancelText?: string,
  ): Promise<boolean> => {
    setContent({
      title,
      message,
      okText: okText || "Ok",
      cancelText: cancelText || "Cancel",
    });
    setShow(true);
    return new Promise((resolve) => {
      resolver.current = resolve;
    });
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    if (resolver.current) {
      resolver.current(true);
    }
    onHide();
    setContent(null);
  };

  const handleCancel = () => {
    if (resolver.current) {
      resolver.current(false);
    }
    onHide();
    setContent(null);
  };

  return (
    <ModalContext.Provider value={modalContext}>
      {children}

      {content && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-black/60 justify-center z-50">
          <div className="bg-white p-6 mt-8 shadow-xl sm:mx-auto sm:max-w-lg sm:px-10">
            <h2 className="text-lg text-gray-800 font-semibold mb-2">
              {content.title}
            </h2>
            <div>
              <label>{content.message}</label>
            </div>
            <div className="mt-6 flex space-x-2">
              <div className="flex-grow" />
              <StyledButton
                text={content.cancelText}
                isSecondaryAction
                onClick={handleCancel}
              />
              <StyledButton text={content.okText} onClick={handleOk} />
            </div>
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType =>
  useContext(ModalContext);

export { useModalShow, useConfirmationModalContext };
