import { ReactElement, useEffect } from "react";

import HeroSection from "../components/sections/HeroSection";
import ActivitiesSection from "../components/sections/ActivitiesSection";
import ResourcesSection from "../components/sections/ResourcesSection";
import ArchiveSection from "../components/sections/ArchiveSection";
import NewsSection from "../components/sections/NewsSection";
import ContentApi from "../api/ContentApi";
import { PageContentCompareFunction } from "../api/interface";
import Logger from "../common/Logger";
import usePageData from "../hooks/usePageData";
import LoadingPage from "./LoadingPage";
import ActivitiesApi from "../api/ActivitiesApi";
import TrainingAndEducationSection from "../components/sections/TrainingAndEducationSection";

/**
 * Home Page layout for the Sustainability Portal.
 * @constructor
 */
export default function HomePage(): ReactElement {
  const { setActivities, setContent, loading, setLoading } = usePageData();

  useEffect(() => {
    if (!loading) {
      return;
    }

    Logger.logFine("LoadingPage content");
    ContentApi.get().then((pageContent) => {
      pageContent.sort(PageContentCompareFunction);
      setContent(pageContent);
      ActivitiesApi.get().then((activityItems) => {
        setActivities(activityItems);
        setLoading(false);
      });
    });
  }, [loading, setActivities, setContent, setLoading]);

  const render = () => {
    // If Loading, return spinner.
    if (loading) {
      return <LoadingPage />;
    }

    // Update sections to use context so that we don't have to pass filtered items lists.
    return (
      <div className="md:mx-16 flex-grow self-center border-x box-border border-gray-300 bg-gray-150 w-full max-w-8xl">
        <HeroSection />
        <div className="flex items-start flex-col px-2 pb-6 space-y-2 md:flex-row md:p-12 md:space-y-0 md:space-x-4">
          <ActivitiesSection />
          <TrainingAndEducationSection />
        </div>
        <NewsSection />

        {/* Static content sections */}
        <ResourcesSection />
        <ArchiveSection />
      </div>
    );
  };
  return render();
}
