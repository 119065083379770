import {
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  GridReadyEvent,
  IDatasource,
  RowClickedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { forwardRef, ReactNode, Ref } from "react";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_SELECTOR,
} from "../../pages/VendorDetailsPage/VendorDetailsProperties/ConstantsGlossary";
import CostcoBlueLoader from "../common/LoadingIndicators";

type DefaultGridProps<T> = {
  rowId: GetRowIdFunc<T>;
  datasource: IDatasource;
  columnDefs: (ColDef<T> | ColGroupDef<T>)[];
  onSortChanged?: (event: SortChangedEvent<T>) => void;
  onRowClicked?: (event: RowClickedEvent<T>) => void;
  onGridReady?: (event: GridReadyEvent<T>) => void;
  noRowsFoundText?: string;
};

const DefaultGridInner = <T,>(
  {
    columnDefs,
    datasource,
    onSortChanged,
    onRowClicked,
    rowId,
    noRowsFoundText,
    onGridReady,
  }: DefaultGridProps<T>,
  ref: Ref<AgGridReact<T>>,
): ReactNode => {
  const loadingOverlay = () => <CostcoBlueLoader />;
  return (
    <AgGridReact<T>
      getRowId={rowId}
      rowModelType="infinite"
      datasource={datasource}
      domLayout="autoHeight"
      columnDefs={columnDefs}
      cacheBlockSize={20}
      infiniteInitialRowCount={DEFAULT_PAGE_SIZE}
      defaultCsvExportParams={{}}
      defaultColDef={{
        headerClass: "defaultTableHeader",
        cellClass: "defaultCell",
      }}
      pagination
      paginationPageSize={DEFAULT_PAGE_SIZE}
      paginationPageSizeSelector={DEFAULT_PAGE_SIZE_SELECTOR}
      valueCache
      rowSelection="single"
      onSortChanged={onSortChanged}
      onRowClicked={onRowClicked}
      columnHoverHighlight
      className="ag-theme-quartz h-96"
      loadingOverlayComponent={loadingOverlay}
      overlayNoRowsTemplate={noRowsFoundText}
      onGridReady={onGridReady}
      ref={ref} // Forward the ref here
    />
  );
};

// Wrap the component with forwardRef
const DefaultGrid = forwardRef(DefaultGridInner) as <T>(
  props: DefaultGridProps<T> & { ref?: Ref<AgGridReact<T>> },
) => ReactNode;

export default DefaultGrid;
