import Axios, { HttpStatusCode } from "axios";
import axios, { AxiosResponse } from "axios";
import Logger from "../common/Logger";
import TokenResponse from "./interface/oauth/TokenResponse";
import { jwtDecode } from "jwt-decode";
import UserInfo from "./interface/oauth/UserInfo";
import pkceChallenge from "pkce-challenge";
import OidcMetadata from "./interface/oauth/OidcMetadata";
import AppSettingsService from "./AppSettingsService";

export default class AuthApi {
  private static oidcMetadata?: OidcMetadata;

  /**
   * Attempts to get the UserInfo object.
   * - If no access_token is present, it checks for an authorization code and attempts to exchange it
   *   for an access token and then calls the introspection endpoint.
   * - If an access_token is present, it calls the oauth introspection endpoint.
   * - If no access_token or authorization_code is present, it returns null.
   */
  public static async getUserInfo(): Promise<UserInfo | null> {
    // Attempt to get code verifier that was saved during the login attempt.
    const codeVerifier = sessionStorage.getItem("cv");
    sessionStorage.removeItem("cv");
    if (codeVerifier) {
      // check for authorization code in query params.
      const newUrl = new URL(window.location.href);
      const authorizationCode: string | null = newUrl.searchParams.get("code");
      newUrl.searchParams.delete("code");
      window.history.replaceState({}, "", newUrl.href);

      if (authorizationCode) {
        let tokenResponse = await AuthApi.getTokens(
          authorizationCode,
          codeVerifier,
        );
        AuthApi.updateValues(tokenResponse);
      }
    }

    // Checks for an access token, which may have been generated above or already present.
    const access_token = sessionStorage.getItem("access_token");
    if (access_token) {
      // User is logged in. Attempt to introspect credentials.
      return AuthApi.introspect();
    }

    return null;
  }

  /**
   * Authorize the client
   *
   * @return An authorization code that cam be used to obtain an access token.
   */
  public static async authorize() {
    const oidcMetadata = await AuthApi.getOidcMetadata();
    let endpoint = oidcMetadata.authorization_endpoint;
    if (!endpoint) {
      // TODO: Redirect to error page. "Client configuration error"
      return Promise.resolve();
    }

    const codeChallenge = await this.createChallenge();
    const currentUrl = window.location.href.split("?")[0];
    const params = new URLSearchParams({
      response_type: "code",
      scope: "openid email profile",
      code_challenge_method: "S256",
      code_challenge: codeChallenge,
      client_id: AppSettingsService.getPingClientId(),
      redirect_uri: currentUrl,
    });

    const location: string = endpoint + "?" + params.toString();
    Logger.logInfo(`Should do auth login at: ${location}`);
    window.location.href = location;
    return Promise.resolve();
  }

  /**
   * Exchange the user authorization token for access_token and refresh_token.
   * @param challengeCode
   * @param codeVerifier
   */
  public static async getTokens(challengeCode: string, codeVerifier: string) {
    const oidcMetadata = await AuthApi.getOidcMetadata();
    let endpoint = oidcMetadata.token_endpoint;

    const currentUrl = window.location.href.split("?")[0];

    const options: any = {
      code: challengeCode,
      grant_type: `authorization_code`,
      client_id: AppSettingsService.getPingClientId(),
      code_verifier: codeVerifier,
      redirect_uri: currentUrl,
    };

    const auth = btoa(`${AppSettingsService.getPingClientId()}:`);
    const headers = {
      Authorization: `Basic ${auth}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const server = axios.create({
      headers,
    });

    return server
      .post(endpoint, options, { headers })
      .then((response: AxiosResponse<TokenResponse>) => {
        return response.data;
      })
      .catch((error) => {
        sessionStorage.clear();
        sessionStorage.setItem("errorTitle", "Unable to authenticate.");
        sessionStorage.setItem(
          "errorMessage",
          "There was a problem authenticating with the server.",
        );
        sessionStorage.setItem(
          "errorDetails",
          error.response.data.error.error_description,
        );
      });
  }

  /**
   * Returns introspection data fetched from PingFed by the BFF.
   * This shouldn't need any parameters, as the Bearer token should be set by middleware.
   */
  public static async introspect() {
    const endpoint: string = "/auth/introspect";
    return Axios.post(endpoint).then(
      (axiosResponse: AxiosResponse<UserInfo>) => {
        return axiosResponse.data;
      },
    ).catch((e) => {
      Logger.logError(`Login failed. Code ${e?.response?.status}`, e);
      return null;
    });
  }

  public static async logout() {
    const oidcMetadata = await AuthApi.getOidcMetadata();

    localStorage.clear();
    sessionStorage.clear();

    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name).then();
      });
    });

    const location: string =
      oidcMetadata.ping_end_session_endpoint ??
      oidcMetadata.end_session_endpoint ??
      window.location.origin;
    Logger.logInfo(`Logging off at: ${location}`);
    window.location.href = location;
  }

  /**
   * Read the oidc metadata to get links to the oauth configuration.
   * @protected
   */
  protected static async getOidcMetadata() {
    const openIdConfigurationEndpoint = AppSettingsService.getOpenIdConfigUrl();
    if (!AuthApi.oidcMetadata && openIdConfigurationEndpoint) {
      const oidcSettings = axios.create();
      const response: AxiosResponse<OidcMetadata> = await oidcSettings.get(
        openIdConfigurationEndpoint,
      );
      AuthApi.oidcMetadata = response.data;
    }
    return AuthApi.oidcMetadata!;
  }

  /**
   * Creates a PKCE challenge code and chalenge code verifier.
   * The verifier is stored in session storage and the challenge code is
   * returned by the function.
   */
  private static async createChallenge(): Promise<string> {
    const pkce = await pkceChallenge();
    sessionStorage.setItem("cv", pkce.code_verifier);
    return pkce.code_challenge;
  }

  private static updateValues(data: TokenResponse | void): UserInfo | null {
    if (data) {
      sessionStorage.setItem("access_token", data.access_token);
      sessionStorage.setItem("id_token", data.id_token!);
      sessionStorage.setItem("refresh_token", data.refresh_token!);
      Axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
      return jwtDecode(data.id_token!);
    }
    Axios.defaults.headers.common.Authorization = '';
    sessionStorage.removeItem("cv");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("refresh_token");
    return null;
  }
}
