import ActivitySeverity from "./ActivitySeverity";

export default interface ActivityItem {
  title: string;
  description: string;
  link?: string;
  severity: ActivitySeverity;
}

export const DEFAULT_ADMIN_ACTIVITY: ActivityItem = {
  title: "No activities need attention at this time.",
  description: "You can add new content in content management.",
  link: "/content",
  severity: ActivitySeverity.LOW,
};

export const NO_ACTIVITIES: ActivityItem = {
  title: "No activities need attention at this time.",
  description: "Enjoy the rest of your day!",
  severity: ActivitySeverity.LOW,
};
