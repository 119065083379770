import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";
import { PageDataContextProvider } from "../context/PageDataContext";

const DefaultLayout = () => (
  <div className="min-h-screen flex flex-col">
    <Header />
    <main className="w-full bg-white flex-grow flex-1 flex flex-col wf">
      <PageDataContextProvider>
        <Outlet />
      </PageDataContextProvider>
    </main>
    <Footer />
    {/** Add global modal panes. */}
    <ToastContainer />
  </div>
);

export default DefaultLayout;
