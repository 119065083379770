import { ReactElement } from "react";
import { Link } from "react-router-dom";
import PageContent from "../../api/interface/PageContent";
import FileIconUtil from "../../util/FileIconUtil";

export default function DocumentContent(props: {
  item: PageContent;
}): ReactElement {
  const getFileIcon = (item: PageContent) => {
    if (!URL.canParse(item.link ?? "")) {
      return <div />;
    }
    const itemUrl = new URL(item.link ?? "");
    const fileEnd = itemUrl.pathname.split(".").pop() ?? "";
    const extension = fileEnd.toLowerCase();
    return <FileIconUtil extension={extension} />;
  };

  const render = () => {
    const { item } = props;
    const content = (
      <div className="first:border-t-0 border-y flex flex-row p-2">
        <div className="flex-none -mt-0.5 ml-1 mr-4 h-8 w-8">
          {getFileIcon(item)}
        </div>
        <div className="-mt-0.5">
          <div className="text-base font-semibold">{item.title}</div>
          <div className="text-sm break-normal">{item.subtitle}</div>
        </div>
      </div>
    );

    if (item.link) {
      return (
        <Link to={item.link} target="_blank">
          {content}
        </Link>
      );
    }
    return content;
  };

  return render();
}
