import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import PageContent from "../api/interface/PageContent";
import ActivityItem from "../api/interface/ActivityItem";

type PageDataContextType = {
  activities: ActivityItem[];
  setActivities: Dispatch<SetStateAction<ActivityItem[]>>;
  content: PageContent[];
  setContent: Dispatch<SetStateAction<PageContent[]>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const PageDataContext = createContext<null | PageDataContextType>(null);

export const PageDataContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [content, setContent] = useState<PageContent[]>([]);
  const [activities, setActivities] = useState<ActivityItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const memoizedValues = useMemo(() => {
    return {
      activities,
      setActivities,
      content,
      setContent,
      loading,
      setLoading,
    };
  }, [activities, content, loading]);

  return (
    <PageDataContext.Provider value={memoizedValues}>
      {children}
    </PageDataContext.Provider>
  );
};

export default PageDataContext;
