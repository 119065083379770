import PageContent from "../../api/interface/PageContent";
import DataTable from "../tables/DataTable";
import QueryParams from "../../api/interface/QueryParams";
import ContentTableColumns from "./ContentTableColumns";
import ColumnDefinition from "../tables/ColumnDefinition";

const ContentTable = (props: {
  items: PageContent[];
  totalItems: number;
  queryParams: QueryParams;
  sortFunction: (columnName: string) => void;
  pageFunction: (pageNumber: number, itemsPerPage: number) => void;
  selectItem: (item: PageContent) => void;
  columns?: ColumnDefinition<PageContent>[];
}) => {
  const render = () => {
    return (
      <DataTable<PageContent>
        columns={props.columns ?? ContentTableColumns}
        data={props.items}
        rowClicked={props.selectItem}
        totalItems={props.totalItems}
        getKey={(item) => item.name}
        queryParams={props.queryParams}
        pageFunction={props.pageFunction}
        sortFunction={props.sortFunction}
      />
    );
  };

  return render();
};

export default ContentTable;
