import { ReactElement } from "react";
import { Link } from "react-router-dom";
import PageContent from "../../api/interface/PageContent";
import { LocalizedUtcDateString } from "../../util/Utils";
import ImageErrorFunction from "../../util/ImageErrorFunction";

export default function NewsContent(props: {
  item: PageContent;
}): ReactElement {
  const render = () => {
    return (
      <Link
        to={props.item.link ?? "#"}
        target="_blank"
        className="group select-none block border border-gray-300 px-4 py-3 shadow-sm uppercase text-gray-800 hover:scale-100 md:hover:scale-105 hover:text-gray-900 md:bg-blue-slate/20 md:flex-none md:w-56 hover:shadow-lg"
      >
        <div className="h-36 w-full rounded md:rounded-none overflow-hidden">
          <img
            className="h-full w-full object-cover group-hover:scale-100 md:group-hover:scale-105 transition-all"
            alt="News"
            src={props.item.image}
            onError={ImageErrorFunction}
          />
        </div>
        <div className="mt-2">{props.item.author}</div>
        <div className="mt-2 normal-case font-semibold text-blue-900 group-hover:font-bold break-words">
          {props.item.title}
        </div>
        <div className="mt-2">{LocalizedUtcDateString(props.item.date)}</div>
      </Link>
    );
  };

  return render();
}
