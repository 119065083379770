import AuthApi from "../api/AuthApi";

/**
 * Login redirect for the Sustainability Portal.
 * @constructor
 */
const LogoutPage = () => {
  const doLogout = () => {
    window.sessionStorage.clear();
    return AuthApi.logout();
  };

  doLogout().then();

  return <div>Logging out...</div>;
};

export default LogoutPage;
