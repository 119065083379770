import { ReactElement } from "react";

/** Renders the previous and next buttons in the news section. */
export default function NewsButton(props: {
  isNext: boolean;
  text: string;
  action: (isNext: boolean) => void;
}): ReactElement {
  const render = () => {
    const { isNext, text, action } = props;
    return (
      <button
        id={`news-${props.isNext ? "next" : "previous"}`}
        type="button"
        onClick={() => action(isNext)}
        className={`absolute md:flex top-0 z-30 items-center justify-center h-full px-2 cursor-pointer group focus:outline-none ${props.isNext ? "right-0" : "left-0"}`}
      >
        <div className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-400/70 border-1 group-hover:bg-gray-200/80 group-focus:ring-gray-500 group-focus:outline-none">
          <svg
            className="w-4 h-4 text-gray-900 group-hover:text-gray-800"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isNext ? "m1 9 4-4-4-4" : "M5 1 1 5l4 4"}
            />
          </svg>
          <span className="sr-only">{text}</span>
        </div>
      </button>
    );
  };

  return render();
}
