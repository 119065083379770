import { ReactElement, TouchEvent, useRef } from "react";
import NewsContent from "../content/NewsContent";

import NewsButton from "../common/NewsButton";
import usePageData from "../../hooks/usePageData";
import ContentType from "../../api/interface/ContentType";

export default function NewsSection(): ReactElement {
  const title = "News";
  const { content } = usePageData();
  const carouselRef = useRef<HTMLDivElement>(null);

  let xDown: number | null = null;

  /** Transforms the panel elements based on a touch event or button press. */
  const pivot = (isNext: boolean, shift: number = 300) => {
    // Disable horizontal carousel on smaller screens.
    const buttons = document.getElementById("newsButtons");
    if (!buttons || window.getComputedStyle(buttons).display === "none") {
      return;
    }

    const padding: number = 32;
    const carousel = carouselRef.current;
    if (carousel) {
      const style = window.getComputedStyle(carousel);
      const matrix = new WebKitCSSMatrix(style.transform);
      const translateX = matrix.m41;
      let newTranslateX = translateX + (isNext ? -1 * shift : shift);
      const rect = carousel.getBoundingClientRect();
      const translateXBounds = carousel.scrollWidth - rect.width + padding;
      if (newTranslateX > translateXBounds) {
        newTranslateX = translateXBounds;
      } else if (newTranslateX < -1 * translateXBounds) {
        newTranslateX = -1 * translateXBounds;
      }
      carousel.style.transform = `translateX(${newTranslateX}px)`;
    }
  };

  const touchStart = (e: TouchEvent<HTMLDivElement>) => {
    xDown = e.touches[0].clientX;
  };

  const touchMove = (e: TouchEvent<HTMLDivElement>): void => {
    if (!xDown) {
      return;
    }
    const xUp = e.touches[0].clientX;

    const xDiff = xDown - xUp;
    if (Math.abs(xDiff) > 5) {
      pivot(xDiff < 0, Math.abs(xDiff) * 3);
    }
  };

  const render = () => {
    const items = content.filter((item) => item.type === ContentType.NEWS);
    let itemContent = (
      <div className="text-center flex-1 flex-grow text-gray-900 my-6">
        No news items have been published.
      </div>
    );
    if (items.length) {
      itemContent = (
        <div className="w-full overflow-hidden">
          <div
            id="newsCarousel"
            ref={carouselRef}
            onTouchStart={touchStart}
            onTouchMove={touchMove}
            className="transition-all mx-auto max-w-4xl my-4 md:flex md:space-x-6 md:px-4 md:justify-center"
          >
            {items.map((item) => (
              <NewsContent key={`news-article-${item.name}`} item={item} />
            ))}
          </div>
          <div id="newsButtons" className="md:block">
            <NewsButton isNext={false} text="" action={pivot} />
            <NewsButton isNext text="" action={pivot} />
          </div>
        </div>
      );
    }

    return (
      <div className="relative w-full bg-white pt-4 md:pb-1">
        <div className="mx-auto text-center text-xl font-semibold text-blue-900">
          {title}
        </div>
        {itemContent}
      </div>
    );
  };

  return render();
}
