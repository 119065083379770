import { ReactElement } from "react";
import Carousel, { ArrowProps } from "react-multi-carousel";
import HeroContent from "../content/HeroContent";
import usePageData from "../../hooks/usePageData";
import ContentType from "../../api/interface/ContentType";
import PageContent from "../../api/interface/PageContent";
import "react-multi-carousel/lib/styles.css";
import HeroIndicatorDot from "../common/HeroIndicator";
import HeroButton from "../common/HeroButton";

/** The speed the panel scrolls through the items */
const AUTOSCROLL_INTERVAL: number = 10000;
const TRANSITION_DURATION = 600;
const CUSTOM_TRANSITION = `transform ${TRANSITION_DURATION}ms ease`;

/** Whether autoplay is set to start on page load. */
const DEFAULT_AUTOPLAY: boolean = true;

const nextText = "Next";
const previousText = "Previous";

export default function HeroSection(): ReactElement {
  const { content } = usePageData();

  const heroItems: PageContent[] = content.filter(
    (item) => item.type === ContentType.HERO,
  );
  const numItems = heroItems.length;

  const RightHeroButton = ({ onClick }: ArrowProps) => {
    return <HeroButton isNext text={nextText} click={onClick!} />;
  };

  const LeftHeroButton = ({ onClick }: ArrowProps) => {
    return <HeroButton isNext={false} text={previousText} click={onClick!} />;
  };

  /** Render the hero items. */
  const renderItems = () => (
    <Carousel
      autoPlay={DEFAULT_AUTOPLAY}
      autoPlaySpeed={AUTOSCROLL_INTERVAL}
      centerMode={false}
      className=""
      containerClass="container-with-dots"
      customRightArrow={<RightHeroButton />}
      customLeftArrow={<LeftHeroButton />}
      customDot={<HeroIndicatorDot />}
      customTransition={CUSTOM_TRANSITION}
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside
      renderDotsOutside
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots
      sliderClass=""
      transitionDuration={TRANSITION_DURATION}
      slidesToSlide={1}
      swipeable
    >
      {heroItems
        .filter((item) => item?.name)
        .map((item, index) => (
          <HeroContent key={`hc-${item.name}`} id={index} item={item} />
        ))}
    </Carousel>
  );

  const render = () => {
    if (numItems === 0) {
      return (
        <div id="hero-main">
          <div className="relative w-full pb-5">
            <img
              src="https://mobilecontent.costco.com/live/resource/img/sustainability-2023/d-sustainablity-hero.jpg"
              alt="Sustainability Portal Banner"
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
      );
    }

    return (
      <div id="hero-main">
        <div className="relative w-full pb-5">{renderItems()}</div>
      </div>
    );
  };

  return render();
}
