import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import UserInfo from "../api/interface/oauth/UserInfo";

type AuthContextType = {
  user?: UserInfo;
  setUser: Dispatch<SetStateAction<UserInfo | undefined>>;
  accessToken?: string;
  setAccessToken: Dispatch<SetStateAction<string | undefined>>;
};

const AuthContext = createContext<null | AuthContextType>(null);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<UserInfo | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const memoizedValues = useMemo(() => {
    return {
      user,
      setUser,
      accessToken,
      setAccessToken,
    };
  }, [user, accessToken]);

  return (
    <AuthContext.Provider value={memoizedValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
