import { ReactElement } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import VendorInfo from "../../api/interface/cdh/VendorInfo";
import { countryFormatter } from "../../pages/VendorDetailsPage/VendorDetailsProperties/VendorDetailsPageColumnDefinitions";

export default interface ColumnDefinition<Type> {
  name: string;
  hideHeaderText?: boolean;
  sortable?: boolean;
  sortValue?: string;
  styles?: string;
  value: (data: Type) => string | ReactElement;
}

const vendorIDColDefs: ColDef<VendorInfo>[] = [
  {
    headerName: "Country / Region",
    field: "company",
    filter: false,
    valueFormatter: countryFormatter,
    flex: 1,
  },
  {
    headerName: "AP Number",
    field: "apNumber",
    filter: false,
    flex: 1,
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    },
  },
  {
    headerName: "AP Suffix",
    field: "apSuffix",
    filter: false,
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    },
    flex: 1,
  },
  {
    headerName: "SAP Number",
    field: "sapNumber",
    filter: false,
    flex: 1,
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    },
  },
];

const vendorNameColDef: ColDef<VendorInfo> = {
  headerName: "Name",
  field: "name",
  filter: false,
  flex: 2,
};

// Column Definitions: Defines the columns to be displayed.
export const defaultColumnHeaders: (
  | ColDef<VendorInfo>
  | ColGroupDef<VendorInfo>
)[] = [
  ...vendorIDColDefs,
  {
    headerName: "BBA Number",
    field: "bbaNumber",
    filter: false,
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    },
    flex: 1,
  },
  vendorNameColDef,
];

export const apVendorsColumnHeaders: (
  | ColDef<VendorInfo>
  | ColGroupDef<VendorInfo>
)[] = [...vendorIDColDefs, vendorNameColDef];
