import { ReactElement } from "react";

export default function ResourcesSection(): ReactElement {
  const title: string = "Resources";

  const render = () => (
    <div className="flex-1 px-4 py-4 w-full">
      <div className="mx-auto text-center text-xl font-semibold text-blue-900">
        {title}
      </div>
      <div className="mx-auto max-w-2xl">
        <div className="px-4">
          <div className="py-2 text-blue-900 text-lg">
            Sourcing Policies &amp; Practices
          </div>
          <div className="pb-1 text-gray-900">
            At Costco, we have instituted a number of policies for sustainable
            sourcing in these areas:
          </div>
          <ul className="space-y-1 pl-4 text-blue underline">
            <li>
              <a
                href="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/SupplierCodeofConduct.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Costco&apos;s Supplier Code of Conduct
              </a>
            </li>
            <li>
              <a
                href="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/16w0604-sustainability-conflict-minerals-policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Conflict Minerals Policy
              </a>
            </li>
            <li>
              <a
                href="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/4f-5PalmOilPolicy2022.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Palm Oil Policy
              </a>
            </li>
            <li>
              <a
                href="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/Costco-Pollinator-Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Policy to Protect Pollinator Health
              </a>
            </li>
          </ul>
          <div className="pb-2 pt-4 text-lg text-blue-900">Packaging FAQs</div>
          <div className="pb-1 text-gray-900">
            We receive many letters and inquiries from our members, who have
            great questions about packaging. For more information, please review
            our{" "}
            <span className="text-blue underline">
              <a
                href="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/4bFAQ_2022_Packaging_Costco.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Packaging FAQ Sheet
              </a>
            </span>
            .
          </div>
        </div>
      </div>
    </div>
  );

  return render();
}
