import ContentStatus from "./ContentStatus";
import ContentType from "./ContentType";

export default interface PageContent {
  /* MongoDB internal id (unique) */
  _id?: string;

  name: string;
  updates?: PageContent;

  /** The author of the content. */
  author?: string;

  /**
   * I'm not sure if we want to store this as a string or as a Date object.
   *
   * The benefit of the date object is that it's easier to localize,
   * but we may want to add a string defining the specific format.
   */
  date?: string;

  /**
   * Url to the thumbnail image.
   */
  image?: string;

  /**
   * Whether the content is active and should be shown on the page.
   * Supported status: [Active, Draft, Archived]
   */
  status: ContentStatus;

  /**
   * Url to navigate to on click.
   */
  link?: string;

  /** Subtitle text for the object */
  subtitle?: string;

  /** Additional Tailwind CSS styles to apply to the child elements. */
  styles?: {
    image?: string;
    title?: string;
    subtitle?: string;
  };

  /** Primary title for the object. */
  title?: string;

  /**
   * Defines which section of the page the item will be shown in.
   *
   * Known values include: DOCUMENTS, HERO, NEWS
   *
   */
  type?: ContentType;
}

/**
 * Compares two PageContent items.
 *
 * Compare order is:
 *  - By date first.
 *    Note: If one item has no date then the item with the date is considered greater.
 *  - By title second.
 *  - By name (UUID) third.
 *
 * @param a The first PageContent item
 * @param b The second PageContent item
 * @constructor
 */
export const PageContentCompareFunction = (a: PageContent, b: PageContent) => {
  if (a.date && b.date) {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    if (aDate < bDate) return 1;
    if (bDate < aDate) return -1;
  }

  if (a.date) {
    // If date exists for item A and not item B, item A is considered greater.
    return -1;
  }

  if (b.date) {
    // If date exists for item B and not item A, item B is considered greater.
    return 1;
  }

  if (a.title && b.title) {
    if (a.title < b.title) return 1;
    if (b.title < a.title) return -1;
  }

  // Name will always be unique.
  return a.name.localeCompare(b.name);
};

export const DEFAULT_PAGE_CONTENT = { name: "", status: ContentStatus.DRAFT };
