import Axios from "axios";

import QueryParams, {
  EMPTY_QUERY_PARAMS,
  parseQueryParams,
} from "./interface/QueryParams";
import { PaginatedResponse } from "./interface/PaginatedResponse";
import ItemData from "./interface/cdh/ItemData";

export default class ItemApi {
  private static async getPaginatedResponse(
    route: string,
    queryParams: QueryParams = EMPTY_QUERY_PARAMS,
  ): Promise<PaginatedResponse<ItemData>> {
    const paginatedResponse: PaginatedResponse<ItemData> = {
      queryParams,
      size: 0,
      items: [],
    };
    try {
      const response = await Axios.get(route + parseQueryParams(queryParams));
      paginatedResponse.items = response.data.items;
      paginatedResponse.queryParams = response.data.queryParams;
      paginatedResponse.size = response.data.size;
    } catch (exception) {
      return Promise.reject(exception);
    }
    return paginatedResponse;
  }

  public static async get(
    queryParams: QueryParams = EMPTY_QUERY_PARAMS,
  ): Promise<PaginatedResponse<ItemData>> {
    return ItemApi.getPaginatedResponse("/items", queryParams);
  }

  public static async getItem(name: string): Promise<ItemData | undefined> {
    try {
      const response = await Axios.get(`/items/${name}`);
      return response.data.item;
    } catch (_exception) {
      return undefined;
    }
  }

  public static async reset(): Promise<PaginatedResponse<ItemData>> {
    return Axios.get("/items/reset").then((response) => {
      return response.data;
    });
  }
}
