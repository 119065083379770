import React, { ReactElement, useState } from "react";
import svgLogo from "../images/svg/logo.svg";
import PermissionService from "../permissions/PermissionService";
import AuthApi from "../api/AuthApi";
import useAuth from "../hooks/useAuth";
import LinkItem from "../components/common/LinkItem";

export default function Header(): ReactElement {
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);

  const { user } = useAuth();

  const onProfileSelected = (event: React.MouseEvent | React.KeyboardEvent) => {
    event.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };

  window.addEventListener("click", () => {
    if (showProfileMenu) {
      setShowProfileMenu(false);
    }
  });

  const renderUserMenu = () => (
    <div
      className={`absolute object-right cursor-pointer select-none -right-[-.5px] z-50 py-2 w-48 h-auto min-h-18 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${showProfileMenu ? "" : "hidden"}`}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu-button"
    >
      <button
        type="button"
        onClick={() => AuthApi.logout()}
        className="block px-4 py-2 text-sm w-full text-blue-80 hover:bg-blue-100 text-center"
        role="menuitem"
        tabIndex={0}
        id="user-menu-item-6"
      >
        Sign out
      </button>
    </div>
  );

  const renderProfileImage = () => {
    const userName: string = user?.givenName ?? "user";
    return (
      <div className="h-full content-center items-center justify-items-end">
        <span className="flex-item material-icons align-middle">
          account_circle
        </span>
        <span className="flex-item pl-1 text-sm font-light align-middle w-full">
          Hello {userName}!
        </span>
        <span className="flex-item material-icons-outlined text-blue-600 align-middle">
          expand_more
        </span>
      </div>
    );
  };

  const renderProfile = () => (
    <div
      role="presentation"
      onKeyDown={(e) => onProfileSelected(e)}
      onClick={(e) => onProfileSelected(e)}
      className="h-full cursor-pointer select-none content-center"
    >
      {renderProfileImage()}
      {renderUserMenu()}
    </div>
  );

  const renderQuickLinks = () => (
    <>
      {PermissionService.hasCreatorRole(user) && (
        <LinkItem
          href="/content"
          label="Manage Content"
          id="header-quick-link-manage-content"
        />
      )}

      {PermissionService.hasVendorsRole(user) && (
        <LinkItem
          href="/vendors"
          label={
            PermissionService.isVendorUser(user)
              ? "Vendor Profile"
              : "Vendor Search"
          }
          id="header-quick-link-vendor-profile-search"
        />
      )}

      {PermissionService.hasItemsRole(user) && (
        <LinkItem
          href="/items"
          label="Item Search"
          id="header-quick-link-item-search"
        />
      )}
    </>
  );
  const largerScreenStyles = "h-[84px] text-base";
  const render = () => (
    <header
      className={`flex-none h-14 text-sm 2xl:${largerScreenStyles} border-b-2 bg-gray-150 border-gray-300 text-blue-600`}
    >
      <div
        id="header-content"
        className="flex flow-row items-center h-full justify-between"
      >
        <div id="branding-area" className="pl-4 flex-1">
          <a href="/" className="flex flex-row">
            <img src={svgLogo as string} className="h-8 mx-2" alt="Logo" />
          </a>
        </div>
        <div
          id="header-links"
          className="h-full flex flex-auto items-center justify-center"
        >
          {renderQuickLinks()}
        </div>
        <div
          id="user-info"
          className="h-full flex flex-1 items-center justify-end"
        >
          {renderProfile()}
        </div>
      </div>
    </header>
  );

  return render();
}
