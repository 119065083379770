import AppSettingsService from "../api/AppSettingsService";
import errorCaution from "../images/svg/errorCaution.svg";
import StyledHeader from "../components/common/StyledHeader";

const ErrorPage = (props: {
  title: string;
  message: string;
  details?: string;
}) => {
  const { title, message, details } = props;

  const apiUrl: string = AppSettingsService.getBaseUrl();
  const isNonProd: boolean =
    apiUrl.includes("costco.internal") ||
    apiUrl.includes("localhost") ||
    apiUrl.includes("np.ct-costco.com");
  return (
    <div>
      <StyledHeader />
      <div className="pt-12 bg-gray-100 h-screen justify-center text-center text-CostcoAction font-Helvetica">
        <div className="mx-auto max-w-4xl">
          <div className="pb-5">
            <div className="flex justify-center items-center pt-20 pb-5">
              <img
                className="w-[100px]"
                src={errorCaution as string}
                alt="errorCaution"
              />
            </div>
            <div className="tracking-widest mt-4">
              <div className="text-4xl block">Oops, something went wrong.</div>
              <div className="pt-16">{title}</div>
              <div className="flex justify-center items-center py-5">
                {message}
              </div>
              {details !== undefined && (
                <div className="pt-5 text-gray-700">{details}</div>
              )}
            </div>
          </div>

          {/* TODO: ONLY RENDER THIS IN NON-PROD */}
          {isNonProd && (
            <div className="py-8 text-gray-900 font-mono text-sm rounded-md bg-gray-200">
              <p>
                Please check your internet connection and ensure you are
                connected to the Costco VPN.
              </p>
              <p>
                If this is your first time connecting to the Sustainability
                Portal,
              </p>
              <a
                className="mt-2 text-xs rounded-md text-blue underline "
                target="_window"
                href={`${apiUrl}/healthcheck`}
              >
                click here
              </a>{" "}
              to trust our server, then reload the page.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
