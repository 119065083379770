import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import PhoneInput from "react-phone-number-input";
import StyledInputProps from "../interface/StyledInputProps";
import errorExclamationSVG from "../../images/svg/error_exclamation.svg";
import Cube from "./Cube";
import DropDown from "./DropDown";
import CheckBox from "./CheckBox";

const inputStyle: string =
  "block mt-0.5 p-2.5 w-full text-base bg-gray-150 text-gray-800 rounded-t border-b-2 border-gray-400 appearance-none peer";
const focusStyle: string =
  "focus:outline-none focus:ring-0 focus:border-CostcoAction";
const readOnlyStyle: string = "bg-gray-200 bg-opacity-60";
const labelStyle: string =
  "text-xs uppercase text-gray-700 pb-2 tracking-widest";
const labelFocusStyle: string = "";

const StyledInput = ({
  id,
  label,
  name,
  type,
  value,
  isRequired,
  readOnly,
  isDropdown,
  isCheckBox,
  dropdownOptions,
  maxLength,
  onOptionSelectChange,
  checkCollection,
  onOptionDelete,
  onCheckChange,
  onPhoneChange,
  onChange,
  additionalClasses,
  placeholder,
  formatError,
}: StyledInputProps): ReactElement => {
  const [expandCheckList, setExpandCheckList] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<
    { value: string; isChecked: boolean }[]
  >([]);
  const checkTarget = useCallback(
    (target: string) => {
      const arr: string[] = value ? value.split(",") : [];
      return arr.length > 0 && arr.includes(target);
    },
    [value],
  );

  useEffect(() => {
    if (isCheckBox) {
      const roles = value ? value.split(",") : [];
      const newList = checkCollection
        ? checkCollection.map((item) => {
            return { value: item, isChecked: roles.includes(item) };
          })
        : [];
      setRoleList(newList);
    }
  }, [isCheckBox, value, checkCollection]);

  const handleOptionSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    return onOptionSelectChange && onOptionSelectChange(e);
  };
  const handleRemoveOption = (optionName: string) => {
    return onOptionDelete && onOptionDelete(optionName);
  };
  const handleExpand = () => {
    setExpandCheckList(!expandCheckList);
  };
  const handleCheckBoxChange = (target: string, isChecked: boolean) => {
    const newCheckboxList =
      roleList.length > 0
        ? roleList.map((el) => {
            if (el.value === target) {
              return { value: el.value, isChecked };
            }
            return { value: el.value, isChecked: checkTarget(el.value) };
          })
        : [];
    const checkedList: string[] = [];
    if (newCheckboxList.length > 0) {
      newCheckboxList.forEach((el) => {
        if (el.isChecked) {
          checkedList.push(el.value);
        }
      });
    }
    setRoleList(newCheckboxList);
    return onCheckChange && onCheckChange(checkedList.join(","));
  };
  const getRoleListString = useCallback(() => {
    const newList: string[] = [];
    roleList.forEach((item) => {
      if (item.isChecked) newList.push(item.value);
    });
    return newList.join(", ");
  }, [roleList]);

  const renderLabel = () => (
    <div>
      <label
        className={`${labelStyle} ${labelFocusStyle}${
          formatError ? "text-red font-semibold" : ""
        }`}
        htmlFor={id}
      >
        {label}
      </label>
      {isRequired && (
        <p className="inline-block ml-[5px] pt-[2px] text-red-500 align-[-3px]">
          *
        </p>
      )}
    </div>
  );
  const renderRegularInput = () => (
    <input
      id={id}
      name={name}
      type={type}
      value={value ?? ""}
      readOnly={readOnly}
      disabled={readOnly}
      onChange={onChange}
      className={`${inputStyle} ${focusStyle} ${readOnly ? readOnlyStyle : ""} ${additionalClasses ?? ""}`}
      maxLength={maxLength}
      placeholder={placeholder || ""}
    />
  );

  const renderPhoneInput = () =>
    onPhoneChange && (
      <PhoneInput
        defaultCountry="US"
        id={id}
        name={name}
        type={type}
        readOnly={readOnly}
        disabled={readOnly}
        value={value ?? ""}
        onChange={onPhoneChange}
        maxLength={maxLength}
        className={`${inputStyle} ${focusStyle} ${readOnly ? readOnlyStyle : ""} ${additionalClasses ?? ""} [&>input]:bg-gray-150`}
        placeholder={placeholder || " "}
      />
    );

  const renderDropDown = () =>
    dropdownOptions && (
      <div>
        <div className="flex-row flex flex-wrap items-center space-x-2 pb-2">
          {value &&
            value.split(",").map((el) => (
              <div key={el} className="flex-item items-center space-2 my-1">
                <Cube name={el} onDelete={handleRemoveOption} />
              </div>
            ))}
        </div>
        <DropDown
          options={dropdownOptions}
          additionalClasses={`${inputStyle} ${focusStyle} ${readOnly ? readOnlyStyle : ""} ${additionalClasses ?? " "}`}
          handleSelectChange={handleOptionSelectChange}
        />
      </div>
    );

  const renderCheckbox = (el: { value: string; isChecked: boolean }) => (
    <li
      key={el.value}
      className="w-full h-10 py-2.5 block appearance-none peer z-10"
    >
      <CheckBox
        value={el.value}
        checked={el.isChecked}
        onChange={handleCheckBoxChange}
        type={type}
        readOnly={readOnly}
        disabled={readOnly}
        placeholder={placeholder || ""}
      />
    </li>
  );
  const renderCheckboxList = () =>
    roleList && (
      <div
        className={`w-full min-h-1/3 h-auto flex-col items-start bg-gray-150  ${additionalClasses || ""}`}
      >
        <div
          className={`flex-item ${inputStyle} ${focusStyle} ${readOnly ? readOnlyStyle : ""}`}
        >
          <button type="button" className="w-full" onClick={handleExpand}>
            <div className="inline-flex float-left">{getRoleListString()}</div>
            <div className="inline-flex float-right right-6 material-icons-outlined absolute text-gray-950 hover:text-CostcoAction z-10">
              {!expandCheckList ? "expand_more" : "expand_less"}
            </div>
          </button>
        </div>
        {expandCheckList && (
          <div className="w-full h-auto">
            <ul className="flex-item mt-2 pl-[-0.5] ml-[-2.5] block w-full ">
              {roleList.length > 0 && roleList.map((el) => renderCheckbox(el))}
            </ul>
          </div>
        )}
      </div>
    );

  return (
    <div className="relative py-2 h-auto">
      {renderLabel()}
      <div className="flex items-center space-x-2">
        {!isDropdown && !isCheckBox && !onPhoneChange && renderRegularInput()}
        {!isDropdown && !isCheckBox && renderPhoneInput()}
        {isDropdown && renderDropDown()}
        {isCheckBox && checkCollection && renderCheckboxList()}
        {formatError && (
          <img
            src={errorExclamationSVG as string}
            className="inline-flex right-1 absolute "
            alt="format error"
          />
        )}
      </div>
    </div>
  );
};

export default StyledInput;
