import { ReactElement } from "react";

export default function Footer(): ReactElement {
  const resources: FooterContent = {
    CA_NOTICE: "CA Notice",
    COOKIE_SETTINGS: "Cookie Settings",
    COPYRIGHT:
      "© 2023 - 2024 Costco Wholesale Corporation. All rights reserved.",
    PRIVACY_CHOICES: "Your Privacy Choices",
    PRIVACY_RIGHTS: "Your Privacy Rights",
    SITE_MAP: "Site Map",
    TERMS: "Terms and Conditions",
  };

  const render = () => (
    <footer className="flex-none w-full md:h-24 bg-blue-800 border-t border-blue-800/50 text-white flex flex-col space-y-4 text-center">
      <div
        id="footer-sections"
        className="flex flex-col text-base font-light mx-auto mt-4 space-y-2 md:space-y-0 md:flex-row md:space-x-6"
      >
        <a
          href="https://www.costco.com/terms-and-conditions-of-use.html"
          rel="noreferrer"
          target="_blank"
        >
          {resources.TERMS}
        </a>
        <a
          href="https://www.costco.com/privacy-policy.html"
          rel="noreferrer"
          target="_blank"
        >
          {resources.PRIVACY_RIGHTS}
        </a>
        {resources.CA_NOTICE && (
          <a
            href="https://www.costco.com/privacy-policy.html#cppa"
            rel="noreferrer"
            target="_blank"
          >
            {resources.CA_NOTICE}
          </a>
        )}
        {resources.PRIVACY_CHOICES && (
          <div className="flex space-x-4">
            <img
              className="h-3 mt-1.5 mr-0.5 pointer shadow-border"
              alt="Cookie Settings"
              src="https://mobilecontent.costco.com/live/resource/img/static-us-landing-pages/icon-privacy-choices.svg"
            />
            <a
              href="https://www.costco.com/DNSMIView"
              rel="noreferrer"
              target="_blank"
            >
              {resources.PRIVACY_CHOICES}
            </a>
          </div>
        )}
      </div>
      <div id="copyright-section" className="text-sm pb-8 md:pb-0">
        {resources.COPYRIGHT}
      </div>
    </footer>
  );

  return render();
}

type FooterContent = {
  CA_NOTICE?: string;
  COOKIE_SETTINGS: string;
  COPYRIGHT: string;
  PRIVACY_CHOICES?: string;
  PRIVACY_RIGHTS: string;
  SITE_MAP: string;
  TERMS: string;
};
