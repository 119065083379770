export const LocalizedUtcDateString = (dateStr: string | undefined) =>
  dateStr === undefined
    ? ""
    : new Date(dateStr).toLocaleDateString(undefined, { timeZone: "UTC" });

export const getTimestamp = (): string => {
  const date = new Date();
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  const hours = ("0" + date.getUTCHours()).slice(-2);
  const minutes = ("0" + date.getUTCMinutes()).slice(-2);
  const seconds = ("0" + date.getUTCSeconds()).slice(-2);
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export const getCurrentLocalDate = (): string => {
  const currentDate = new Date();
  const minutesOffset = currentDate.getTimezoneOffset();
  const msOffset = 60000 * minutesOffset;
  const dateWithOffset = new Date(currentDate.valueOf() - msOffset);
  return dateWithOffset.toISOString().slice(0, 10);
};
