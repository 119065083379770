import { AxiosProgressEvent } from "axios";
import FileApi from "../api/FileApi";
import FileIconUtil from "./FileIconUtil";
import UserInfo from "../api/interface/oauth/UserInfo";
import ImageErrorFunction from "./ImageErrorFunction";

export default class FileInfo {
  private user?: UserInfo;

  public file?: File;

  public extension: string = "";

  private name: string = "";

  public size: number = 0;

  public isUploaded: boolean = false;

  private link: string = "";

  constructor(user?: UserInfo, file?: File) {
    this.user = user;
    this.file = file;
    this.size = file?.size ?? 0;
    this.setName(file?.name ?? "");
  }

  public setName(name: string) {
    this.name = name;
    this.inferExtension(name);
  }

  public setLink(link: string) {
    this.link = link;
    this.inferExtension(link);
  }

  private inferExtension(text: string) {
    const split = text.split(".");
    this.extension = split.length > 0 ? (split.pop() ?? "") : "";
    if (this.extension.length > 4) {
      this.extension = "";
    }
  }

  public getName() {
    return this.name;
  }

  public upload = async (
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  ): Promise<string> => {
    if (!this.file) return Promise.resolve("");
    return FileApi.uploadPageContent(
      this.user,
      this.file,
      onUploadProgress,
    ).then((result) => {
      this.link = result;
      this.isUploaded = true;
      return result;
    });
  };

  public getFileSize = () => {
    const kb = this.size / 1024;
    const mb = kb / 1024;
    return mb > 0.6 ? `${mb.toFixed(2)} MB` : `${kb.toFixed(2)} KB`;
  };

  public renderIcon = () => {
    if (this.extension === "" || this.extension === "html") {
      return (
        <div className="flex-none px-2">
          <span className="material-icons-outlined md-48 text-gray-500">
            public
          </span>
        </div>
      );
    }
    return (
      <div className="flex-none px-2 h-16 w-16">
        <FileIconUtil extension={this.extension as string} />
      </div>
    );
  };

  public renderImage = () => (
    <div className="flex-grow px-2 max-h-48 max-w-full">
      <img
        className="object-contain max-h-48 max-w-full"
        alt="Thumbnail"
        src={this.link}
        onError={ImageErrorFunction}
      />
    </div>
  );

  public getNiceLink() {
    if (this.link.startsWith(window.location.origin)) {
      const linkUrl = new URL(this.link);
      linkUrl.search = "";
      return linkUrl.toString();
    }
    return this.link;
  }

  public renderPreview = (isImage?: boolean) => (
    <div className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-200 bg-gray-50 hover:bg-gray-100">
      <div className="flex flex-col items-center justify-center pb-6 pt-5">
        {isImage ? this.renderImage() : this.renderIcon()}
        <p className="mb-0.5 mt-2 text-sm text-gray-700">
          <span className="font-semibold">{this.name}</span>
        </p>
        <p className="mb-2 text-sm text-gray-600">
          <span className="font-extralight">{this.getNiceLink()}</span>
        </p>
      </div>
    </div>
  );
}
