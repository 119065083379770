import { ReactElement } from "react";

import { Link } from "react-router-dom";
import PageContent from "../../api/interface/PageContent";
import ImageErrorFunction from "../../util/ImageErrorFunction";

type HeroItemProps = {
  id: number;
  item: PageContent;
};

export default function HeroContent(props: HeroItemProps): ReactElement {
  const render = () => {
    const { id, item } = props;
    if (!item) {
      return <div />;
    }
    const coreDisplayClasses =
      "flex flex-none align-center h-96 w-full justify-center";

    return (
      <Link
        id={`hero-item-${id}`}
        to={item.link ?? "#"}
        target="_blank"
        className={coreDisplayClasses}
      >
        <div className="h-full w-1/2 md:w-1/3 bg-green-700 px-2 text-white flex flex-col justify-center">
          <div className="text-xl md:text-3xl text-right">{item.title}</div>
          <div className="mt-2 text-sm text-right italic">{item.subtitle}</div>
        </div>
        <img
          className={`h-full w-1/2 md:w-2/3 object-cover object-center ${item.styles?.image ?? ""}`}
          alt="News article"
          src={item.image}
          onError={ImageErrorFunction}
        />
      </Link>
    );
  };

  return render();
}
