export default function HeroButton({
  isNext,
  text,
  click,
}: {
  isNext: boolean;
  click: () => void;
  text: string;
}) {
  const render = () => {
    return (
      <button
        id={`hero-${isNext ? "next" : "previous"}`}
        type="button"
        onClick={() => click()}
        className={`absolute hidden md:flex top-0 z-30 items-center justify-center h-full px-2 cursor-pointer group focus:outline-none ${isNext ? "right-0" : "left-0"}`}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-400 group-hover:bg-gray-200 group-focus:ring-gray-500/70 group-focus:outline-none">
          <svg
            className="w-4 h-4 text-gray-800 group-hover:text-gray-900"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isNext ? "m1 9 4-4-4-4" : "M5 1 1 5l4 4"}
            />
          </svg>
          <span className="sr-only">{text}</span>
        </span>
      </button>
    );
  };
  return render();
}
