import Axios from "axios";

import { v4 } from "uuid";
import PageContent from "./interface/PageContent";
import ContentMessage from "./interface/ContentMessage";
import ContentMessageStatus from "./interface/ContentMessageStatus";
import QueryParams, {
  EMPTY_QUERY_PARAMS,
  parseQueryParams,
} from "./interface/QueryParams";
import { PaginatedResponse } from "./interface/PaginatedResponse";

export default class ContentApi {
  private static async getPaginatedResponse(
    route: string,
    queryParams: QueryParams = EMPTY_QUERY_PARAMS,
  ): Promise<PaginatedResponse<PageContent>> {
    const paginatedResponse: PaginatedResponse<PageContent> = {
      queryParams,
      size: 0,
      items: [],
    };
    try {
      const response = await Axios.get(route + parseQueryParams(queryParams));
      paginatedResponse.items = response.data.items;
      paginatedResponse.queryParams = response.data.queryParams;
      paginatedResponse.size = response.data.size;
    } catch (exception) {
      return Promise.reject(exception);
    }
    return paginatedResponse;
  }

  private static async getResponse(route: string): Promise<PageContent[]> {
    return this.getPaginatedResponse(route, {
      itemsPerPage: 100,
    }).then((response) => response.items);
  }

  public static async get(): Promise<PageContent[]> {
    return ContentApi.getResponse("/content");
  }

  public static async getItem(name: string): Promise<PageContent | undefined> {
    try {
      const response = await Axios.get(`/content/item/${name}`);
      return response.data.item;
    } catch (_exception) {
      return undefined;
    }
  }

  public static async getAll(
    queryParams: QueryParams = EMPTY_QUERY_PARAMS,
  ): Promise<PaginatedResponse<PageContent>> {
    return ContentApi.getPaginatedResponse("/content/all", queryParams);
  }

  public static async loadMetadata(
    link: string,
  ): Promise<PageContent | undefined> {
    try {
      const response = await Axios.post("/content/loadMetadata", { url: link });
      return response.data.content;
    } catch (_exception) {
      return undefined;
    }
  }

  public static async copy(item: PageContent): Promise<ContentMessage> {
    try {
      const response = await Axios.post("/content/copy", {
        contentName: item.name,
      });
      return {
        content: response.data,
        message: "Content copied",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  public static async create(
    content: PageContent,
  ): Promise<ContentMessage> {

    if (!content.name) {
      content.name = v4();
    }
    return Axios.post("/content", content)
      .then((response) => ({
        content: response.data.items,
        message: "Content created",
        status: ContentMessageStatus.SUCCESS,
      }))
      .catch((exception) => {
        return Promise.reject(exception);
      });
  }

  public static async discard(item: PageContent): Promise<ContentMessage> {
    try {
      const response = await Axios.put("/content/discard", {
        contentName: item.name,
      });
      return {
        content: [response.data.item],
        message: "Content discarded",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  public static async publish(item: PageContent): Promise<ContentMessage> {
    try {
      const response = await Axios.put("/content/publish", {
        contentName: item.name,
      });
      return {
        content: response.data.published,
        message: "Content published",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  public static async archive(item: PageContent): Promise<ContentMessage> {
    try {
      const response = await Axios.put("/content/archive", {
        contentName: item.name,
      });
      return {
        content: response.data.archived,
        message: "Content archived",
        status: ContentMessageStatus.SUCCESS,
      };
    } catch (exception) {
      return Promise.reject(exception);
    }
  }

  public static reset() {
    return Axios.get("/content/reset").then((response) => {
      return response.data;
    });
  }

  public static update(
    item: PageContent
  ): Promise<ContentMessage> {
    return Axios.put("/content/update", item)
      .then((response) => ({
        content: [response.data.updated],
        message: "Content updated",
        status: ContentMessageStatus.SUCCESS,
      }))
      .catch((exception) => {
        return Promise.reject(exception);
      });
  }
}
