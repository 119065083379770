import { ReactElement, ChangeEvent, FC } from "react";

type DropDownProps = {
  options: string[];
  additionalClasses?: string;
  handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};
const DropDown: FC<DropDownProps> = (props: DropDownProps): ReactElement => {
  const { options, additionalClasses, handleSelectChange } = props;
  return (
    <select
      className={`${additionalClasses ?? " "}`}
      onChange={handleSelectChange}
      value=""
    >
      <option value="" disabled>
        Select
      </option>
      {options.map((option) => (
        <option key={option as string} value={option as string}>
          {option}
        </option>
      ))}
    </select>
  );
};
export default DropDown;
