const NoAccessPage = () => (
  <div className="pt-8 text-gray-800 justify-center text-center">
    <div className="mx-auto max-w-xl">
      <div className="tracking-widest mt-4">
        <span className="material-icons-outlined text-9xl mb-2">gpp_bad</span>
        <span className="text-4xl block">
          You are not authorized to access the Sustainability Portal.
        </span>
        <span className="mt-6 text-xl block max-w-lg mx-auto">
          If you would like access, please reach out to the Costco
          Sustainability team or your vendor admin.
        </span>
      </div>
    </div>
  </div>
);

export default NoAccessPage;
