import { ReactElement } from "react";
import ItemBlade from "../common/ItemBlade";

const TrainingAndEducationSection = (): ReactElement => {
  const title: string = "Training & Education";
  const itemBlades = [
    {
      title: "Policies & Guidelines",
      icon: "security",
      picture: "",
      path: "/training-and-education",
    },
    {
      title: "Tools & Templates",
      icon: "construction",
      path: "/training-and-education",
    },
    { title: "Job Aids", icon: "work", path: "/training-and-education" },
    {
      title: "Videos",
      icon: "ondemand_video",
      path: "/training-and-education",
    },
  ];

  return (
    <div className="flex-1 border border-gray-300 bg-white px-4 py-2 shadow-md w-full">
      <div className="flex">
        <div className="text-xl text-blue-900 leading-8">{title}</div>
        <div className="flex-grow" />
        <a
          className="text-sm text-blue-900 leading-8 hover:underline hover:text-CostcoAction"
          href="/t&e"
        >
          View all
        </a>
      </div>
      <div className="grid grid-cols-2 gap-7 p-8">
        {itemBlades?.length &&
          itemBlades.map((item) => (
            <ItemBlade
              title={item.title}
              key={item.title}
              icon={item.icon}
              path={item.path}
              additionalStyles="h-44 w-68"
            />
          ))}
      </div>
    </div>
  );
};

export default TrainingAndEducationSection;
