import { useState } from "react";
import JsonView from "react18-json-view";
import ItemData from "../../api/interface/cdh/ItemData";
import expandMoreSVG from "../../images/svg/expand_more.svg";
import useAuth from "../../hooks/useAuth";
import PermissionService from "../../permissions/PermissionService";

export interface ItemInfoProps {
  data: ItemData;
}

const ItemInfo = (props: ItemInfoProps) => {
  const { data } = props;
  const { user } = useAuth();
  const { description, secondaryDescription, id, bbaId, itemThumbnail } = data;
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const itemTitle: string = description ?? "Unknown Item";
  const itemSubtitle = secondaryDescription ?? "Unknown";
  const itemId = id;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const renderedData = JSON.parse(JSON.stringify(data));
  delete renderedData.id;
  delete renderedData._id;
  delete renderedData.bbaId._id;
  if (PermissionService.isVendorUser(user)) {
    delete renderedData.department;
  }

  const apNumber: string = String(bbaId?.apNumber ?? "-");
  const bbaNumber: string = String(bbaId?.bbaNumber ?? "-");
  const renderData = () => {
    const handleClick = () => {
      setShowDetails(!showDetails);
    };
    return !PermissionService.isVendorUser(user) ? (
      <div className="bg-white">
        <button
          type="button"
          id="show-details"
          className="text-sm font-semibold"
          onClick={handleClick}
        >
          <label htmlFor="show-details" className="inline-block">
            Show more details
          </label>
          <img
            className="inline-block mx-2 align-middle h-[22px]"
            src={expandMoreSVG as string}
            alt="expand for more item details"
          />
        </button>
        {showDetails && (
          <div className="overflow-y-auto h-auto text-xs mt-2 bg-gray-500 bg-opacity-10">
            <JsonView src={renderedData} collapsed={false} theme="a11y" />
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  };

  const vendorName = bbaId?.name ?? "Unknown Vendor";

  return (
    <div className="flex mt-4 h-auto w-auto">
      {itemThumbnail && (
        <img
          alt="Item thumbnail"
          className="h-48 object-contain mr-12"
          src={itemThumbnail}
        />
      )}
      <div className="pt-2 text-gray-700">
        <div className="font-semibold text-xl">{itemTitle}</div>
        <div className="text-base font-light">{itemSubtitle}</div>
        <div className="text-sm pt-4 grid grid-cols-2 gap-x-8">
          <span>Item ID</span>
          <span>{itemId}</span>
          <span>AP Vendor Number</span>
          <span>{apNumber}</span>
          <span>Vendor Name</span>
          <span>{vendorName}</span>
          <span>BBA Vendor Number</span>
          <span>{bbaNumber}</span>
        </div>
        {/* {renderData()} */}
      </div>
    </div>
  );
};

export default ItemInfo;
