import { ReactElement, useRef } from "react";
import { useNavigate } from "react-router-dom";

interface ItemBladeProps {
  title?: string;
  icon?: string;
  imgLink?: string;
  path?: string;
  searchParams?: URLSearchParams;
  key?: string;
  tabIndex?: number;
  additionalStyles?: string;
}

const ItemBlade = (props: ItemBladeProps): ReactElement => {
  const {
    title,
    path,
    icon,
    imgLink,
    searchParams,
    key,
    tabIndex,
    additionalStyles,
  } = props;
  const navigate = useNavigate();
  const bladeRef = useRef<HTMLDivElement>(null);
  const defaultStyle =
    "rounded h-21 w-full border-2 border-solid border-gray-300 rounded-4xl";
  const hoverStyle = "shadow-lg";
  const backgroundStyle = imgLink
    ? `bg-gradient-to-r bg-[url(${imgLink})]`
    : " ";

  const handldClick = () => {
    if (bladeRef.current) {
      bladeRef.current.focus();
    }
    const searchString = searchParams ? searchParams.toString() : "";
    navigate({ pathname: path, search: searchString });
  };
  return (
    <div
      className={`flex-item flex items-center flex-col ${backgroundStyle} ${defaultStyle} hover:${hoverStyle} ${additionalStyles || ""}`}
      key={key}
      tabIndex={tabIndex || 0}
      role="button"
      onClick={handldClick}
      onKeyDown={handldClick}
      ref={bladeRef}
    >
      {icon && (
        <div className="pt-5 items-center px-6 material-icons-outlined text-7xl text-CostcoAction">
          {icon}
        </div>
      )}
      <div className="pt-6 inline-flex text-lg">{title}</div>
    </div>
  );
};
export default ItemBlade;
