import { DotProps } from "react-multi-carousel";
import React from "react";

const HeroIndicatorDot = ({
  onClick,
  index,
  active,
}: DotProps): React.ReactElement => {
  return (
    <button
      type="button"
      onClick={() => onClick!()}
      data-state={active ? "active" : ""}
      className="hidden md:flex w-3 h-3 rounded-full bg-black/50 hover:bg-black data-[state=active]:bg-black mx-1"
      aria-label={`Slide ${index}`}
    />
  );
};

export default HeroIndicatorDot;
