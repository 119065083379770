import { ReactElement } from "react";

export interface FilterItemProps {
  name: string;
  value: string;
}

export default function FilterSection(props: {
  title: string;
  items: FilterItemProps[];
  selected: Set<string>;
  action: (value: string) => void;
}): ReactElement {
  const FilterItemRenderer = (item: FilterItemProps) => {
    const { name, value } = item;
    const isSelected = props.selected.has(value);
    const standardClasses: string = "select-none border px-4 py-1 text-sm";
    const hoverClasses: string = "hover:bg-gray-300 hover:text-black";
    const selectedClasses: string = isSelected
      ? "bg-CostcoAction text-white border-CostcoAction"
      : "text-black border-gray-500";
    return (
      <button
        key={`filter-item-${value}`}
        type="button"
        onClick={() => props.action(value)}
        className={[standardClasses, hoverClasses, selectedClasses].join(" ")}
      >
        {name}
      </button>
    );
  };

  const render = () => (
    <div className="px-6">
      <div className="text-sm uppercase tracking-wide text-gray-700">
        {props.title}
      </div>
      <div className="mt-2">
        {props.items.map((item) => {
          return FilterItemRenderer(item);
        })}
      </div>
    </div>
  );

  return render();
}
