import { ReactElement, useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import PageContent from "../api/interface/PageContent";
import ContentApi from "../api/ContentApi";
import FilterSection, {
  FilterItemProps,
} from "../components/common/FilterSection";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import ContentTable from "../components/content/ContentTable";
import StyledButton from "../components/common/StyledButton";
import useAuth from "../hooks/useAuth";
import PermissionService from "../permissions/PermissionService";
import Role from "../permissions/interface/Role";
import QueryParams from "../api/interface/QueryParams";

export default function ContentManagementPage(): ReactElement {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [items, setItems] = useState<PageContent[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const statusFilterItems: FilterItemProps[] = [
    {
      name: "Archived",
      value: "archived",
    },
    {
      name: "Draft",
      value: "draft",
    },
    {
      name: "Live",
      value: "published",
    },
  ];

  const contentFilterItems: FilterItemProps[] = [
    { name: "Documents", value: "documents" },
    { name: "Hero", value: "hero" },
    { name: "News", value: "news" },
  ];

  const queryParams: QueryParams = {
    itemsPerPage: parseInt(searchParams.get("s") ?? "5", 10),
    pageNumber: parseInt(searchParams.get("p") ?? "1", 10),
    sortBy: searchParams.get("sortBy") ?? "title",
    contentFilter: searchParams.get("content") ?? "all",
    statusFilter: searchParams.get("status") ?? "all",
  };

  /** Checks for any pending messages to display toast messages. */
  useEffect(() => {
    const successMessage = localStorage.getItem("successMessage");
    if (successMessage) {
      localStorage.removeItem("successMessage");
      toast.success(successMessage);
    }
  }, []);

  /** Queries the API for page data, when sort, pagination, or filter params change. */
  useEffect(() => {
    const qp = {
      itemsPerPage: parseInt(searchParams.get("s") ?? "5", 10),
      pageNumber: parseInt(searchParams.get("p") ?? "1", 10),
      sortBy: searchParams.get("sortBy") ?? "title",
      sortDescending: searchParams.has("sortDescending"),
      contentFilter: searchParams.get("content") ?? "all",
      statusFilter: searchParams.get("status") ?? "all",
    };
    ContentApi.getAll(qp).then((paginatedResponse) => {
      setItems(paginatedResponse.items);
      setTotalItems(paginatedResponse.size);
    });
  }, [searchParams]);

  const getSearchParams = () => {
    const search: string[] = [];
    // Add pagination
    search.push(`p=${queryParams.pageNumber}`, `s=${queryParams.itemsPerPage}`);
    // Add filters
    search.push(
      `status=${queryParams.statusFilter}`,
      `content=${queryParams.contentFilter}`,
    );
    // Add sort options
    search.push(`sortBy=${queryParams.sortBy}`);
    return search;
  };

  const updateSearchParams = () => {
    const search = getSearchParams().join("&");
    if (search !== location.search) {
      navigate({
        pathname: `/content`,
        search,
      });
    }
  };

  const updatePagination = (pageNumber: number, itemsPerPage: number) => {
    if (queryParams.itemsPerPage !== itemsPerPage) {
      pageNumber = 1;
    }
    queryParams.pageNumber = pageNumber;
    queryParams.itemsPerPage = itemsPerPage;
    updateSearchParams();
  };

  const updateSort = (sortBy: string) => {
    if (queryParams.sortBy === sortBy) {
      queryParams.sortBy = `-${sortBy}`;
    } else {
      queryParams.sortBy = sortBy;
    }
    updateSearchParams();
  };

  const updateStatusFilter = (status: string) => {
    queryParams.pageNumber = 1;
    queryParams.statusFilter =
      status === queryParams.statusFilter ? "all" : status;
    updateSearchParams();
  };

  const updateContentFilter = (content: string) => {
    queryParams.pageNumber = 1;
    queryParams.contentFilter =
      content === queryParams.contentFilter ? "all" : content;
    updateSearchParams();
  };

  /** Navigates to a page for creating/viewing/modifying a PageContent item. */
  const manageContent = (pageContent: PageContent | undefined) => {
    const id = pageContent?.name ?? "new";
    navigate({
      pathname: `/content/item/${id}`,
      search: getSearchParams().join("&"),
    });
  };

  const renderActionBar = () => {
    const hasCreatePermissions = PermissionService.hasPermissionLevel(
      user,
      Role.COSTCO_CONTENTMGMT_ADMIN,
      Role.COSTCO_CONTENTMGMT_CREATOR,
    );
    return (
      <div
        id="actionBar"
        className="flex flex-col md:flex-row py-4 flex-none border-b bg-white shadow sticky z-10 top-12"
      >
        <FilterSection
          title="Filter Status"
          action={updateStatusFilter}
          items={statusFilterItems}
          selected={new Set([queryParams.statusFilter ?? ""])}
        />
        <div className="mt-2 border-l border-gray-400" />
        <FilterSection
          title="Filter Content type"
          action={updateContentFilter}
          items={contentFilterItems}
          selected={new Set([queryParams.contentFilter ?? ""])}
        />
        {hasCreatePermissions && (
          <div className="flex-grow flex space-x-2 ml-6 mr-8 mt-4 md:my-auto justify-start md:justify-end">
            <StyledButton
              text="Create"
              onClick={() => {
                manageContent(undefined);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const render = () => (
    <div className="min-h-screen flex flex-col">
      <div className="sticky top-0 z-10">
        <Header />
        {renderActionBar()}
      </div>
      <main className="w-full flex-grow flex-1 flex flex-col wf bg-blue-slate/10">
        <div className="flex-row flex-grow self-center w-full box-border relative">
          <div
            id="main-wrapper"
            className="px-8 py-4 w-full text-left relative"
          >
            <div className="overflow-x-auto w-full md:p-4 min-w-96">
              <ContentTable
                items={items}
                totalItems={totalItems}
                selectItem={manageContent}
                queryParams={queryParams}
                pageFunction={updatePagination}
                sortFunction={updateSort}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ToastContainer position="bottom-right" />
    </div>
  );

  return render();
}
