import { useContext } from "react";
import PageDataContext from "../context/PageDataContext";

const usePageData = () => {
  const pageDataContext = useContext(PageDataContext);

  if (!pageDataContext) {
    throw new Error("No PageDataProvider specified");
  }

  return pageDataContext;
};

export default usePageData;
