import { ChangeEvent, ReactElement } from "react";
import ContentType from "../../api/interface/ContentType";
import DocumentContent from "./DocumentContent";
import NewsContent from "./NewsContent";
import HeroContent from "./HeroContent";
import PageContent from "../../api/interface/PageContent";
import ContentActionBar from "./ContentActionBar";
import ContentStatus from "../../api/interface/ContentStatus";
import PermissionService from "../../permissions/PermissionService";
import useAuth from "../../hooks/useAuth";

/** Content View is used for viewing published and archived content. */
export default function ContentView(params: {
  pageContent: PageContent;
  stepId?: number;
  currentStep?: number;
  contentChangedAction?: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void | undefined;
  isModified?: boolean;
  stepAction?: (a: number) => void | undefined;
}): ReactElement {
  const { user } = useAuth();
  const {
    pageContent,
    stepId,
    currentStep,
    contentChangedAction,
    stepAction,
    isModified,
  } = params;

  const contentEditable: boolean =
    (pageContent.status === ContentStatus.NEW ||
      pageContent.status === ContentStatus.DRAFT) &&
    PermissionService.hasCreatorRole(user);

  const handleContentChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (contentChangedAction !== undefined) {
      contentChangedAction(e);
    }
  };

  const renderSelect = () => (
    <div id="content-area-floating-select" className="relative my-3 w-full">
      <label
        htmlFor="content-area-select"
        className="text-xs uppercase text-gray-700 pb-2 tracking-widest peer-focus:text-CostcoAction"
      >
        Content area
      </label>
      <select
        id="content-area-select"
        name="type"
        value={pageContent?.type ?? ""}
        onChange={handleContentChanged}
        className="p-2.5 w-full block bg-gray-150 text-gray-800 border-b border-gray-400 rounded focus:outline-0 focus:border-CostcoAction"
      >
        <option value="" hidden>
          Select a content area
        </option>
        <option value={ContentType.DOCUMENTS}>Documents</option>
        <option value={ContentType.HERO}>Hero</option>
        <option value={ContentType.NEWS}>News</option>
      </select>
    </div>
  );

  const renderContent = () => (
    <section
      className={[
        "basis-full grow-0 shrink-0 transition-all",
        currentStep !== stepId ? "opacity-0" : "",
      ].join(" ")}
    >
      {contentEditable && renderSelect()}
      <div id="previewContentArea" className="my-12 flex justify-center">
        {pageContent !== undefined &&
          pageContent.type === ContentType.DOCUMENTS && (
            <div className="flex-grow">
              <DocumentContent item={pageContent} />
            </div>
          )}
        {pageContent !== undefined && pageContent.type === ContentType.NEWS && (
          <NewsContent item={pageContent} />
        )}
        {pageContent !== undefined && pageContent.type === ContentType.HERO && (
          <HeroContent id={-1} item={pageContent} />
        )}
      </div>
      <div id="previewButtonArea" className="my-8 flex space-x-2">
        <ContentActionBar
          pageContent={pageContent}
          isModified={isModified}
          previousStepAction={() => {
            if (stepAction && currentStep) {
              stepAction(currentStep - 1);
            }
          }}
        />
      </div>
    </section>
  );

  const render = () => {
    if (pageContent) return renderContent();
    return <div>Error getting data</div>;
  };

  return render();
}
