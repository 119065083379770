import { ReactElement } from "react";
import ActivityContent from "../content/ActivityContent";
import usePageData from "../../hooks/usePageData";
import useAuth from "../../hooks/useAuth";
import PermissionService from "../../permissions/PermissionService";
import { DEFAULT_ADMIN_ACTIVITY, NO_ACTIVITIES } from "../../api/interface";
import ActivityItem from "../../api/interface/ActivityItem";

export default function ActivitiesSection(): ReactElement {
  const title = "Activities";
  const { activities } = usePageData();
  const { user } = useAuth();

  const render = () => {
    const displayedActivities: ActivityItem[] = activities;
    if (displayedActivities.length === 0) {
      displayedActivities.push(
        PermissionService.hasApproverRole(user)
          ? DEFAULT_ADMIN_ACTIVITY
          : NO_ACTIVITIES,
      );
    }

    return (
      <div className="flex-1 border border-gray-300 bg-white px-4 py-2 shadow-md w-full">
        <div className="text-xl text-blue-900">{title}</div>
        <div id="activity-list">
          {displayedActivities.map((activity) => (
            <ActivityContent
              title={activity.title}
              key={activity.link}
              description={activity.description}
              link={activity.link}
              severity={activity.severity}
            />
          ))}
        </div>
      </div>
    );
  };

  return render();
}
