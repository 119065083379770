import { ReactElement } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ItemData from "../../api/interface/cdh/ItemData";
import ItemBreadcrumbs from "./ItemBreadcrumbs";
import ItemInfo from "./ItemInfo";

export interface ItemViewProps {
  data?: ItemData;
  section?: string;
}

const ItemView = (props: ItemViewProps): ReactElement => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const switchSection = (sectionId: string) => {
    if (props.data) {
      // eslint-disable-next-line no-underscore-dangle
      const pathname = `/item/${props.data._id}/${sectionId}`;
      navigate({
        pathname,
        search: searchParams.toString(),
      });
    }
  };

  const renderSectionHeaders = () => {
    const sections = [
      { value: "contacts", name: "Contacts" },
      { value: "activities", name: "Activities" },
    ];
    return (
      <div className="w-full mt-8 flex">
        {sections.map((section) => {
          const isSelected = section.value === props.section;
          const classList = [
            "text-center flex-grow text-sm pb-4 border-b-2 text-gray-900 hover:text-black hover:border-CostcoAction cursor-pointer",
          ];
          classList.push(
            isSelected ? "font-bold border-CostcoAction" : "border-gray-400",
          );
          return (
            <button
              type="button"
              onClick={() => switchSection(section.value)}
              key={`${section.value}-section`}
              data-value={section.value}
              className={classList.join(" ")}
            >
              {section.name}
            </button>
          );
        })}
      </div>
    );
  };

  const ItemDetails = () => {
    return (
      <div>
        {renderSectionHeaders()}
        <div className="mt-8" />
      </div>
    );
  };

  const render = () => {
    if (props.data) {
      return (
        <>
          <ItemBreadcrumbs item={props.data} />
          <ItemInfo data={props.data} />
          {ItemDetails()}
        </>
      );
    }
    return <div>Error getting data</div>;
  };

  return render();
};

export default ItemView;
