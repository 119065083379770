import { ReactElement } from "react";
import { Link } from "react-router-dom";
import ActivityItem from "../../api/interface/ActivityItem";
import ActivitySeverity from "../../api/interface/ActivitySeverity";

export default function ActivityContent(props: ActivityItem): ReactElement {
  const getSeverityColor = (severity: ActivitySeverity) => {
    switch (severity) {
      case ActivitySeverity.HIGH:
        return "bg-red-500";
      case ActivitySeverity.MEDIUM:
        return "bg-yellow-200";
      case ActivitySeverity.LOW:
        return "bg-green-300";
      default:
        return "transparent";
    }
  };

  const render = () => {
    const severityColor = getSeverityColor(props.severity);
    let content = (
      <div>
        <div className="font-semibold text-gray-900">{props.title}</div>
        <div className="py-1 text-gray-900">
          <div
            className={`mr-2 text-sm inline-block h-2 w-2 rounded-lg ${severityColor}`}
          />
          <span>{props.description}</span>
        </div>
      </div>
    );

    if (props.link) {
      content = <Link to={props.link}>{content}</Link>;
    }

    return <div className="px-2 py-3 border-t first:border-t-0">{content}</div>;
  };

  return render();
}
