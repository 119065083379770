import { SyntheticEvent } from "react";
import fallback from "../images/fallback.png";

export default function ImageErrorFunction(
  e: SyntheticEvent<HTMLImageElement>,
) {
  const imageElement = e.target as HTMLImageElement;
  if (imageElement) {
    imageElement.onerror = null;
    imageElement.src = fallback;
  }
}
