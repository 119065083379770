import { Entitlement, RetrieveUserResponse, VendorUser } from "./RetrieveUserResponse";
import Contact from "../Contact";

const entitlementNameMap = new Map<string, {sortIndex: number, displayName: string}>(
  [
    ["EAM-VendorAdmin", { sortIndex: 0, displayName: "Vendor Admin"}],
    ["VSP-ESG_Lead", { sortIndex: 1,  displayName: "ESG Lead"}],
    ["VSP-READ", { sortIndex: 2,  displayName: "Registered Portal User"}],
  ]
);

const getRole = (entitlements: Entitlement[]) => {
  return entitlements
    .map((entitlement: Entitlement) => entitlementNameMap.get(entitlement.entitlementId))
    .filter((nameEntry) => nameEntry !== undefined)
    .sort((a,b) => a.sortIndex - b.sortIndex)
    .map(entry => entry!.displayName)
    .join(", ");
};

const convertVendorUserToContact = (vendorUser: VendorUser): Contact => {
  const profile = vendorUser.profile;
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email,
    phone: profile.phoneNumber,
    sustainabilityRole: getRole(vendorUser.entitlement),
  };
}

export default convertVendorUserToContact;
