import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import ItemData from "../../../api/interface/cdh/ItemData";
import VendorInfo from "../../../api/interface/cdh/VendorInfo";
import Contact from "../../../api/interface/Contact";
import parsePhoneNumberFromString from "libphonenumber-js";
import { Categories } from "./ConstantsGlossary";
import Category from "../../../api/interface/cdh/Category";

/* This file controls all the Column Definitions/Header on Vendor Details Page */

/* Start of Columns Definitions for Items Ag-grid Table on Vendor Profile Page */
export const countryFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }

  switch (params.value) {
    case 1:
    case 3:
    case 8:
    case 10:
    case 12:
    case 16:
    case 17:
    case 18:
    case 24:
    case 34:
    case 35:
    case 36:
    case 38:
    case 39:
    case 44:
    case 45:
    case 46:
    case 90:
    case 91:
    case 95:
    case 96:
    case 97:
    case 98:
    case 120:
      return "US";

    case 4:
    case 14:
    case 31:
    case 32:
    case 33:
    case 50:
    case 53:
    case 54:
    case 55:
    case 56:
    case 72:
    case 78:
    case 122:
    case 123:
    case 126:
    case 127:
    case 128:
    case 150:
      return "CA";

    case 19:
      return "HK";

    case 27:
    case 885:
      return "JP";

    case 28:
    case 886:
      return "KR";

    case 47:
    case 48:
    case 49:
    case 101:
      return "MX";

    case 61:
    case 124:
      return "GB";

    case 71:
      return "ES";

    case 87:
      return "AU";

    case 88:
      return "FR";

    case 878:
      return "SE";

    case 879:
      return "NZ";

    case 881:
      return "TW";

    case 887:
      return "IS";

    case 888:
    case 889:
    case 890:
    case 891:
    case 892:
    case 893:
    case 894:
    case 895:
    case 896:
    case 897:
    case 898:
      return "CN";

    default:
      return "Others";
  }
};

export const organicOrPalmOilFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }
  return params.value.toLowerCase() === "y" ? "YES" : "NO";
};

export const commodityFormatter = (params: ValueFormatterParams) => {
  if (!params.value) {
    return "";
  }
  const arr = params.value.toUpperCase().split(/COMMODITY/);
  return arr[arr.length - 1].replace("-", "").trim();
};

const phoneNumberFormatter = (contactParam: ValueFormatterParams<Contact>) => {
  if (!contactParam.data) {
    return "";
  }

  let inputNumber = contactParam.data.phone;

  if (!inputNumber) {
    return "";
  }

  let extension: string | null = null;

  // Extract extension if present in the format ";ext=XXXX"
  const extMatch = inputNumber.match(/;ext=(\d+)/);
  if (extMatch) {
    extension = extMatch[1];
    inputNumber = inputNumber.replace(/;ext=\d+/, "");
  }

  // Remove any semicolons from the input number
  inputNumber = inputNumber.replace(/;/g, "");

  try {
    // Parse the phone number
    const phoneNumber = parsePhoneNumberFromString(inputNumber);

    if (phoneNumber) {
      // Format the number in national format
      let formattedNumber = `(+${phoneNumber.countryCallingCode}) ${phoneNumber.formatNational()}`;

      // Append the extension if it exists
      if (extension) {
        formattedNumber += ` ext. ${extension}`;
      }

      return formattedNumber;
    } else {
      console.error(`Error parsing phone number: ${inputNumber}`);
      return inputNumber;
    }
  } catch (e) {
    console.error("Error parsing phone number:", e);
    return inputNumber;
  }
};

const categoryFormatter =
  (categoryName: Categories) => (itemParam: ValueFormatterParams<ItemData>) => {
    if (!itemParam.data) {
      return "";
    }

    let category: Category;

    const item = itemParam.data;
    switch (categoryName) {
      case Categories.Cat1:
        category = item.department?.category1!;
        break;
      case Categories.Cat2:
        category = item.department?.category2!;
        break;
      case Categories.Cat3:
        category = item.department?.category3!;
        break;
      default:
        return "";
    }

    const code = category.code;
    const description = category.description;
    return `${code} - ${description}`;
  };

const CountryColDef: ColDef<ItemData> = {
  headerName: "Country / Region",
  field: "country",
  flex: 0.5,
  valueFormatter: countryFormatter,
};
const ItemNumberColDef: ColDef<ItemData> = {
  headerName: "Item Number",
  field: "id",
  flex: 0.5,
  //pinned: "left",
  sortable: true,
  sortingOrder: ["asc", "desc"],
  comparator: (valueA, valueB) => {
    return Number(valueA) - Number(valueB);
  },
};

export const CertificationsColDefs: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Certifications",
  children: [
    {
      headerName: "Certification 1",
      field: "cert1",
      flex: 1,
    },
    {
      columnGroupShow: "open",
      headerName: "Certification 2",
      field: "cert2",
      flex: 1,
    },
    {
      columnGroupShow: "open",
      headerName: "Certification 3",
      field: "cert3",
      flex: 1,
    },
    // no cert4 or cert5 available right now
    // {
    //   columnGroupShow: "open",
    //   headerName: "Certification 4",
    //   field: "cert4",
    //   flex: 1,
    // },
    // {
    //   columnGroupShow: "open",
    //   headerName: "Certification 5",
    //   field: "cert5",
    //   flex: 1,
    // },
  ],
};

export const CommoditiesColDefs: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Commodities",
  children: [
    {
      headerName: "Primary Commodity",
      field: "primaryCommodity",
      flex: 1,
      valueFormatter: commodityFormatter,
    },
    {
      columnGroupShow: "open",
      headerName: "Secondary Commodity",
      field: "secondaryCommodity",
      flex: 1,
      valueFormatter: commodityFormatter,
    },
  ],
};
export const PalmOilColDef: ColDef<ItemData> = {
  headerName: "Contains Palm Oil",
  field: "containsPalmOil",
  flex: 0.5,
  valueFormatter: organicOrPalmOilFormatter,
};
export const CategoriesColDefs: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Categories",
  flex: 1,
  children: [
    {
      headerName: "Category 1",
      field: "department.category1.code",
      flex: 1,
      valueFormatter: categoryFormatter(Categories.Cat1),
    },
    {
      columnGroupShow: "open",
      headerName: "Category 2",
      field: "department.category2.code",
      flex: 0.5,
      valueFormatter: categoryFormatter(Categories.Cat2),
    },
    {
      columnGroupShow: "open",
      headerName: "Category 3",
      field: "department.category3.code",
      flex: 0.5,
      valueFormatter: categoryFormatter(Categories.Cat3),
    },
  ],
};

export const DescriptionColDefsForCostcoUsers:
  | ColDef<ItemData>
  | ColGroupDef<ItemData> = {
  headerName: "Descriptions",
  children: [
    {
      headerName: "Description 1",
      field: "description",
      flex: 1,
    },
    {
      columnGroupShow: "open",
      headerName: "Description 2",
      field: "secondaryDescription",
      flex: 1,
    },
  ],
};

export const DescriptionColDefsForVendorUsers:
  | ColDef<ItemData>
  | ColGroupDef<ItemData> = {
  headerName: "Description",
  field: "description",
  flex: 1,
};

export const OrganicColDef: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Organic",
  field: "organic",
  flex: 0.5,
  valueFormatter: organicOrPalmOilFormatter,
};

export const DepartmentColDef: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Department Number",
  field: "department.id",
  flex: 0.5,
};

export const VendorBBAColDef: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Vendor BBA Number",
  field: "bbaNumber",
  flex: 0.5,
};
export const VendorNameColDef: ColDef<ItemData> | ColGroupDef<ItemData> = {
  headerName: "Vendor Name",
  field: "bbaId.name",
  flex: 0.5,
  sortable: false,
};
export const BBAVendorItemTableColumns: (
  | ColDef<ItemData>
  | ColGroupDef<ItemData>
)[] = [CountryColDef, ItemNumberColDef];
/* End of Columns Definitions for Items Ag-grid Table on Vendor Profile Page */

/* Start of Columns Definitions for Contacts Ag-grid Table on Vendor Profile Page */
export const DefaultContactsTableColumns: (
  | ColDef<Contact>
  | ColGroupDef<Contact>
)[] = [
  {
    headerName: "First Name",
    sortable: true,
    field: "firstName",
    flex: 1,
  },
  {
    headerName: "Last Name",
    sortable: true,
    field: "lastName",
    flex: 1,
  },
  {
    headerName: "Email",
    sortable: true,
    field: "email",
    flex: 1,
  },
  {
    headerName: "Phone",
    sortable: false,
    field: "phone",
    flex: 1,
    valueFormatter: phoneNumberFormatter,
  },
];

/* End of Columns Definitions for Contacts Ag-grid Table on Vendor Profile Page */

/* Start of Columns Definitions for BBA/Child Vendor Ag-grid Table on Vendor Profile Page */
export const DefaultChildVendorColumns: (
  | ColDef<VendorInfo>
  | ColGroupDef<VendorInfo>
)[] = [
  {
    headerName: "BBA Number",
    field: "bbaNumber",
    comparator: (valueA, valueB) => {
      return Number(valueA) - Number(valueB);
    },
    flex: 1,
    sortable: true,
  },
  {
    headerName: "Name",
    field: "name",
    flex: 3,
    sortable: true,
  },
];
/* End of Columns Definitions for BBA/Child Vendor Ag-grid Table on Vendor Profile Page */

