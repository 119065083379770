import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import ContentStatus from "../../api/interface/ContentStatus";
import StyledButton from "../common/StyledButton";
import PageContent from "../../api/interface/PageContent";
import ContentApi from "../../api/ContentApi";
import useAuth from "../../hooks/useAuth";
import PermissionService from "../../permissions/PermissionService";
import { useConfirmationModalContext } from "../../context/ModalContext";

export default function ContentActionBar(params: {
  pageContent: PageContent;
  isModified?: boolean;
  previousStepAction?: () => void;
  useMiniBar?: boolean;
}) {
  const modalContext = useConfirmationModalContext();

  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pageContent, previousStepAction, isModified, useMiniBar } = params;

  const [working, setWorking] = useState<boolean>(false);

  const previousStep = () => {
    if (previousStepAction) {
      previousStepAction();
    }
  };

  const navigateOnDone = () => {
    if (useMiniBar) {
      navigate(0);
    } else {
      navigate({ pathname: `/content`, search: searchParams.toString() });
    }
  };

  const archive = async () => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Archive content",
      <div>
        Are you sure you want to archive this content?
        <br />
        The content will no longer be visible to users.
      </div>,
      "Archive",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await ContentApi.archive(pageContent);
    localStorage.setItem("successMessage", "Content successfully archived");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const copyToDraft = async () => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Copy content",
      <div>Are you sure you want to copy this content to a new draft?</div>,
      "Copy",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await ContentApi.copy(pageContent);
    localStorage.setItem("successMessage", "Content successfully copied");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const createItem = async () => {
    if (working) return false;
    setWorking(true);
    const pc = pageContent;
    pc.status = ContentStatus.DRAFT;
    await ContentApi.create(pc);
    localStorage.setItem("successMessage", "Content successfully created");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const discard = async () => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Delete content ",
      <div>
        Are you sure you want to delete this draft content?
        <br />
        This action cannot be undone.
      </div>,
      "Delete",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await ContentApi.discard(pageContent);
    localStorage.setItem("successMessage", "Content successfully deleted");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const modify = async () => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Save Changes",
      <div>Are you sure you want to save changes to this draft?</div>,
      "Save",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await ContentApi.update(pageContent);
    localStorage.setItem("successMessage", "Content successfully updated");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const publish = async () => {
    if (working) return false;
    setWorking(true);
    const confirm = await modalContext.showConfirmation(
      "Publish content",
      <div>
        Are you sure you want to publish this content?
        <br />
        The content will become visible to all users.
      </div>,
      "Publish",
    );
    if (!confirm) {
      setWorking(false);
      return false;
    }
    await ContentApi.publish(pageContent);
    localStorage.setItem("successMessage", "Content successfully published");
    setWorking(false);
    navigateOnDone();
    return true;
  };

  const renderFull = () => {
    const hasCreatorPerms = PermissionService.hasCreatorRole(user);
    if (pageContent.status === ContentStatus.NEW) {
      // Previous step and Create item
      return (
        <>
          <StyledButton text="Previous" onClick={previousStep} />
          <div className="flex-grow" />
          <StyledButton
            text="Create item"
            onClick={createItem}
            title={
              hasCreatorPerms
                ? "Create draft"
                : "Please select a content type before creating the draft"
            }
            disabled={pageContent.type === undefined}
          />
        </>
      );
    }

    function getSaveChangesButtonTooltip() {
      if (hasCreatorPerms && isModified) {
        return "Save Changes";
      }
      if (hasCreatorPerms) {
        return "Modify content before saving";
      }
      return "You do not have permissions to save changes to this draft.";
    }

    const hasApproverPerms = PermissionService.hasApproverRole(user);
    if (pageContent.status === ContentStatus.DRAFT) {
      // Delete, Save Changes, and Publish
      return (
        <>
          <StyledButton text="Previous" onClick={previousStep} />
          <div className="flex-grow" />
          <StyledButton
            text="Delete"
            onClick={discard}
            title={
              hasCreatorPerms
                ? "Delete content"
                : "You do not have permissions to delete this content."
            }
            disabled={!hasCreatorPerms}
          />
          <StyledButton
            text="Save changes"
            onClick={modify}
            title={getSaveChangesButtonTooltip()}
            disabled={!hasCreatorPerms || !isModified}
          />
          <StyledButton
            text="Publish"
            onClick={publish}
            title={
              hasApproverPerms
                ? "Publish content"
                : "You do not have permissions to publish this content."
            }
            disabled={!hasApproverPerms || isModified}
          />
        </>
      );
    }

    if (pageContent.status === ContentStatus.ACTIVE) {
      // Archive and Copy to Draft
      return (
        <>
          <StyledButton text="Previous" onClick={previousStep} />
          <div className="flex-grow" />
          <StyledButton
            text="Archive"
            onClick={archive}
            title={
              hasApproverPerms
                ? "Archive content"
                : "You do not have permissions to archive this content."
            }
            disabled={!hasApproverPerms}
          />
          <StyledButton
            text="Copy to draft"
            onClick={copyToDraft}
            title={
              hasCreatorPerms
                ? "Make a copy of this content as a new draft item"
                : "You do not have permissions to copy this content."
            }
            disabled={!hasCreatorPerms}
          />
        </>
      );
    }

    if (pageContent.status === ContentStatus.ARCHIVE) {
      // Copy to draft
      return (
        <>
          <StyledButton text="Previous" onClick={previousStep} />
          <div className="flex-grow" />
          <StyledButton
            text="Copy to draft"
            onClick={copyToDraft}
            title={
              hasCreatorPerms
                ? "Make a copy of this content as a new draft item"
                : "You do not have permissions to copy this content."
            }
            disabled={!hasCreatorPerms}
          />
        </>
      );
    }
    return <span>No actions available</span>;
  };

  const renderMiniBar = () => (
    <>
      {(pageContent.status === ContentStatus.ACTIVE ||
        pageContent.status === ContentStatus.ARCHIVE) &&
        PermissionService.hasCreatorRole(user) && (
          <button
            type="button"
            className="material-icons-outlined md-24 px-1 hover:text-gray-800 cursor-pointer"
            title="Copy to draft"
            onClick={copyToDraft}
          >
            file_copy
          </button>
        )}

      {pageContent.status === ContentStatus.DRAFT &&
        PermissionService.hasCreatorRole(user) && (
          <button
            type="button"
            className="material-icons-outlined md-24 px-1 hover:text-gray-800 cursor-pointer"
            title="Delete"
            onClick={discard}
          >
            delete
          </button>
        )}

      {pageContent.status === ContentStatus.ACTIVE &&
        PermissionService.hasApproverRole(user) && (
          <button
            type="button"
            className="material-icons-outlined md-24 px-1 hover:text-gray-800 cursor-pointer"
            title="Archive"
            onClick={archive}
          >
            archive
          </button>
        )}

      {pageContent.status === ContentStatus.DRAFT &&
        PermissionService.hasApproverRole(user) && (
          <button
            type="button"
            className="material-icons-outlined md-24 px-1 hover:text-gray-800 cursor-pointer"
            title="Publish"
            onClick={publish}
          >
            publish
          </button>
        )}
    </>
  );

  const render = () => {
    if (useMiniBar) return renderMiniBar();
    return renderFull();
  };

  return render();
}
