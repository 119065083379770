import { ReactElement, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ItemView from "../components/cdh/ItemView";
import ItemData from "../api/interface/cdh/ItemData";
import ItemApi from "../api/ItemApi";

export default function ItemDetailPage(): ReactElement {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [itemData, setItemData] = useState<ItemData>();

  useEffect(() => {
    if (params.id! === "new") {
      return;
    }
    ItemApi.getItem(params.id!).then((item) => {
      setItemData(item);
    });
  }, [params.id]);

  const close = async () => {
    navigate({ pathname: `/items`, search: searchParams.toString() });
  };

  const render = () => {
    return (
      <div className="flex-grow self-center w-full max-w-8xl px-4 md:px-16 box-border">
        <div id="main-wrapper" className="w-full text-left relative">
          <div
            role="presentation"
            className="relative w-full h-full bg-white overflow-x-hidden"
          >
            <button
              type="button"
              className="absolute top-8 right-0 text-gray-500 hover:text-gray-800 cursor-pointer"
              onClick={close}
            >
              <span className="material-icons-outlined">close</span>
            </button>
            <ItemView data={itemData} section={params.section} />
          </div>
        </div>
      </div>
    );
  };

  return render();
}
